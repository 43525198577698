import Header from "./Header";
import Loading from "./Loading";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {unixTimestampToDate} from "../service/DateService";
import {useNavigate} from "react-router-dom";
import {cancelSubscription, fetchAdminDashboardSubscription} from "../service/AdminService";

function AdminSubscription() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const path = window.location.pathname.split("/");
    const subscriptionId = path[path.length - 1];
    const [subscriptionError, setSubscriptionError] = useState(false);
    const [cancelSubscriptionToggle, setCancelSubscriptionToggle] = useState(true);

    const [subscriptionInfo, setSubscriptionInfo] = useState({
        id: "1234",
        name: "test",
        description: "test description",
        firstName: "Brett",
        lastName: "Rexius",
        email: "brettrexius@gmail.com",
        created: "5/2/2024",
        amount: 10,
        active: true,
        cardNo: 4242,
        currentPeriodStart: 1234,
        currentPeriodEnd: 1234

    });

    useEffect(() => {
        if (!localStorage.getItem("raAccessToken")) {
            navigate("/login");
        }
        try {
            setLoading(true)
            fetchAdminDashboardSubscription(true, subscriptionId)
                .then(subscriptionInfoResponse => {
                    setSubscriptionInfo(subscriptionInfoResponse);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading subscription content:", error);
                    setSubscriptionError(true);
                    setLoading(false);
                });

        } catch (error) {
            console.error("Error loading subscription content:", error);
            setSubscriptionError(true);
            setLoading(false);
        }
    }, [cancelSubscriptionToggle]);

    function cancel() {
        if (!subscriptionInfo.active) {
            window.alert("Subscription is not active");
        } else {
            const cancelConfirmation = window.confirm("Cancel Subscription?");
            if (cancelConfirmation) {
                try {
                    setLoading(true)
                    cancelSubscription(true, subscriptionId)
                        .then(cancelSubscriptionResponse => {
                            console.log(cancelSubscriptionResponse)
                            if (!cancelSubscriptionResponse.active) {
                                setCancelSubscriptionToggle(!cancelSubscriptionToggle);
                                window.alert("Subscription Canceled!");
                            } else {
                                window.alert("Could not cancel subscription");
                            }
                            setLoading(false);
                        })
                        .catch(error => {
                            console.error("Error loading subscription content:", error);
                            setSubscriptionError(true);
                            setLoading(false);
                        });

                } catch (error) {
                    console.error("Error loading subscription content:", error);
                    setSubscriptionError(true);
                    setLoading(false);
                }
            }
        }

    }


    const subscriptionContent = (
        <div className={"subscription-content"}>
            <div className={"subscription-subheading"}>{subscriptionInfo.name}</div>
            {/*<div className={"subscription-amount"}>${subscriptionInfo.amount}</div>*/}
            <div className={"subscription-infos"}>
                <div className={"subscription-info"}>
                    <div className={"subscription-info-heading"}>Name</div>
                    <div className={"subscription-info-content dashboard-td-inner"}>{subscriptionInfo.name}</div>
                </div>
                <div className={"subscription-info"}>
                    <div className={"subscription-info-heading"}>Description</div>
                    <div className={"subscription-info-content dashboard-td-inner"}>{subscriptionInfo.description}</div>
                </div>
                <div className={"subscription-info"}>
                    <div className={"subscription-info-heading"}>Amount</div>
                    <div className={"subscription-info-content dashboard-td-inner"}>${subscriptionInfo.amount}</div>
                </div>
                <div className={"subscription-info"}>
                    <div className={"subscription-info-heading"}>Created</div>
                    <div className={"subscription-info-content"}>{unixTimestampToDate(subscriptionInfo.created)}</div>
                </div>
                <div className={"subscription-info"}>
                    <div className={"subscription-info-heading"}>Current Period</div>
                    <div
                        className={"subscription-info-content dashboard-td-inner"}>{unixTimestampToDate(subscriptionInfo.currentPeriodStart)} - {unixTimestampToDate(subscriptionInfo.currentPeriodEnd)}</div>
                </div>
                <div className={"subscription-info"}>
                    <div className={"subscription-info-heading"}>Status</div>
                    <div
                        className={"subscription-info-content dashboard-td-inner"}>{subscriptionInfo.active ? 'Active' : 'Canceled'}</div>
                </div>
                <div className={"subscription-info"}>
                    <div className={"subscription-info-heading"}>Client Name</div>
                    <div className={"subscription-info-content"}><a href={"/admin/user/" + subscriptionInfo.userId}
                                                                    className={"dashboard-link dashboard-td-inner"}>{subscriptionInfo.firstName} {subscriptionInfo.lastName}</a>
                    </div>
                </div>
                <div className={"subscription-info"}>
                    <div className={"subscription-info-heading"}>Client Email</div>
                    <div className={"subscription-info-content"}><a href={"/admin/user/" + subscriptionInfo.userId}
                                                                    className={"dashboard-link dashboard-td-inner"}>{subscriptionInfo.email}</a>
                    </div>
                </div>
                <div className={"subscription-info"}>
                    <div className={"subscription-info-heading"}>ID</div>
                    <div className={"subscription-info-content dashboard-td-inner"}>{subscriptionInfo.id}</div>
                </div>
            </div>
            <div className={"send-wrapper mobile-margin-bottom-0"} style={{textAlign: 'center'}}>
                <button onClick={() => navigate("/admin")} className="send">Account</button>
                <button onClick={() => cancel()} className="send">Cancel</button>
            </div>
        </div>
    );

    return (
        <div className={"admin page"}>
            <Header/>
            <div className={"small-heading"}>SUBSCRIPTION</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"}>
                    {loading ?
                        <Loading text={"Fetching Subscription Info"}/>
                        :
                        subscriptionError ?
                            <div>Error loading subscription content</div>
                            :
                            subscriptionContent
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AdminSubscription;