function Loading(props) {

    const loading = "Loading"
    return (
        <div className={"loading"}>
            <span className={"loading-text"}>{props.text ? props.text : loading}</span>
            <span className={"loading-dots"}>
            <div></div>
            <div></div>
            <div></div>
            </span>
        </div>
    )
}

export default Loading;