import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchAdminDashboardSubscription, fetchAdminDashboardUser} from "../service/AdminService";
import {unixTimestampToDate} from "../service/DateService";
import Header from "./Header";
import Loading from "./Loading";
import Footer from "./Footer";

const border = 'solid 2px #b619ff';
const none = 'none'

function AdminUser() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const path = window.location.pathname.split("/");
    const userId = path[path.length - 1];
    const [userError, setUserError] = useState(false);

    const [userInfo, setUserInfo] = useState({
        "id": 6,
        "firstName": "Brett",
        "lastName": "Rexius",
        "email": "brettrexius@gmail.com",
        "dateCreated": 1712293137751,
        "stripeId": "cus_ProUPPtpwXvTWX",
        "stripeInvoicePrefix": "C759B3A1",
        "paymentMethod": {
            "id": "pm_1PBldeDWKCrBGE2tZaRSUBJM",
            "expMonth": 5,
            "expYear": 2028,
            "lastFour": 4242,
            "network": "visa",
            "funding": "credit"
        },
        "subscriptions": [
            {
                "id": "sub_1PBlglDWKCrBGE2t3nl4T2Pn",
                "created": 1714602707,
                "email": "brettrexius@gmail.com",
                "firstName": "Brett",
                "lastName": "Rexius",
                "stripeInvoicePrefix": "C759B3A1",
                "name": "test5",
                "description": "test desc 5",
                "customerId": "cus_ProUPPtpwXvTWX",
                "amount": 10.0,
                "startDate": 1714602707,
                "active": true
            }
        ],
        "paymentLinks": [
            {
                "id": "plink_1PCr85DWKCrBGE2tJficFBTO",
                "name": "test payment link 3",
                "active": true,
                "url": "https://buy.stripe.com/test_28o8ys50w5VY4ikeUW",
                "lineItemId": "li_Q2x2h6oMd8zRmj",
                "amount": 25.0,
                "description": "test payment 3 description",
                "created": 1714861949,
                "customerId": "6",
                "customerStripeId": "cus_ProUPPtpwXvTWX",
                "firstName": "Brett",
                "lastName": "Rexius",
                "email": "brettrexius@gmail.com",
                "stripeInvoicePrefix": "C759B3A1"
            },
            {
                "id": "plink_1PCBlaDWKCrBGE2tsCQaJ9PU",
                "name": "test payment link 2",
                "active": true,
                "url": "https://buy.stripe.com/test_6oEeWQ0Kg3NQ7uw3cd",
                "lineItemId": "li_Q2GIOohLhYJLZ9",
                "amount": 10.0,
                "description": "test payment link 2 description",
                "created": 1714702950,
                "customerId": "6",
                "customerStripeId": "cus_ProUPPtpwXvTWX",
                "firstName": "Brett",
                "lastName": "Rexius",
                "email": "brettrexius@gmail.com",
                "stripeInvoicePrefix": "C759B3A1"
            }
        ],
        "charges": [
            {
                "id": "ch_3PCr9qDWKCrBGE2t1Qr2f3ey",
                "email": "brettrexius@gmail.com",
                "firstName": "Brett",
                "lastName": "Rexius",
                "stripeId": "cus_ProUPPtpwXvTWX",
                "amount": 25.0,
                "date": 1714862058
            },
            {
                "id": "ch_3PCCloDWKCrBGE2t3gynPYq2",
                "email": "brettrexius@gmail.com",
                "firstName": "Brett",
                "lastName": "Rexius",
                "stripeId": "cus_ProUPPtpwXvTWX",
                "amount": 10.0,
                "date": 1714706808
            },
            {
                "id": "ch_3PBlglDWKCrBGE2t132bMI0Q",
                "email": "brettrexius@gmail.com",
                "firstName": "Brett",
                "lastName": "Rexius",
                "stripeId": "cus_ProUPPtpwXvTWX",
                "amount": 10.3,
                "date": 1714602708
            }
        ]
    });

    useEffect(() => {
        if (!localStorage.getItem("raAccessToken")) {
            navigate("/login");
        }
        try {
            setLoading(true)
            fetchAdminDashboardUser(true, userId)
                .then(userInfoResponse => {
                    setUserInfo(userInfoResponse);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading user content:", error);
                    setUserError(true);
                    setLoading(false);
                });

        } catch (error) {
            console.error("Error loading user content:", error);
            setUserError(true);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        setUserDashboardContent(userContent); // This sets the initial content

    }, [userInfo]); // Re-run effect when adminDashboardInfo changes

    const paymentLinksContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Payment Links</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-18-percent"}>ID</th>
                    <th className={"th-width-18-percent"}>Name</th>
                    <th className={"th-width-18-percent"}>Description</th>
                    <th className={"th-width-18-percent"}>Date</th>
                    <th className={"th-width-18-percent"}>Status</th>
                    <th className={"th-width-18-percent"}>Amount</th>
                </tr>
                </thead>
                <tbody>
                {userInfo.paymentLinks.map((paymentLink, index) => (
                    <tr key={index} className={"dashboard-tr"}>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>{paymentLink.id}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>{paymentLink.name}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>{paymentLink.description}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>{unixTimestampToDate(paymentLink.created)}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>{paymentLink.paid ? 'Paid' : 'Active'}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>${paymentLink.amount}</a>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );

    const chargesContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Payments</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-40-percent"}>ID</th>
                    <th className={"th-width-40-percent"}>Date</th>
                    <th className={"th-width-40-percent"}>Amount</th>
                </tr>
                </thead>
                <tbody>
                {userInfo.charges.map((charge, index) => (
                    <tr key={index} className={"dashboard-tr"}>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/charge/" + charge.id}
                                                                     className={"dashboard-link"}>{charge.id}</a></div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/charge/" + charge.id}
                                                                     className={"dashboard-link"}>{unixTimestampToDate(charge.date)}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/charge/" + charge.id}
                                                                     className={"dashboard-link"}>${charge.amount}</a>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );

    const userContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>{userInfo.firstName} {userInfo.lastName}</div>

            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-10-percent"}>ID</th>
                    <th className={"th-width-25-percent"}>Email</th>
                    <th className={"th-width-25-percent"}>Created</th>
                    <th className={"th-width-25-percent"}>Stripe ID</th>
                </tr>
                </thead>
                <tbody>
                <tr className={"dashboard-tr"}>
                    <td className="dashboard-td">
                        <div className={"dashboard-td-inner"}><a href={"admin/user/" + userInfo.id}
                                                                 className={"dashboard-link"}>{userInfo.id}</a></div>
                    </td>
                    <td className={"dashboard-td"}>
                        <div className={"dashboard-td-inner"}><a href={"admin/user/" + userInfo.id}
                                                                 className={"dashboard-link"}>{userInfo.email}</a></div>
                    </td>
                    <td className={"dashboard-td"}>
                        <div className={"dashboard-td-inner"}><a href={"admin/user/" + userInfo.id}
                                                                 className={"dashboard-link"}>{unixTimestampToDate(userInfo.dateCreated / 1000)}</a>
                        </div>
                    </td>
                    <td className={"dashboard-td"}>
                        <div className={"dashboard-td-inner"}><a href={"admin/user/" + userInfo.id}
                                                                 className={"dashboard-link"}>{userInfo.stripeId}</a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );

    const subscriptionsContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Subscriptions</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-18-percent"}>ID</th>
                    <th className={"th-width-18-percent"}>Name</th>
                    <th className={"th-width-18-percent"}>Description</th>
                    <th className={"th-width-18-percent"}>Date</th>
                    <th className={"th-width-18-percent"}>Status</th>
                    <th className={"th-width-18-percent"}>Amount</th>
                </tr>
                </thead>
                <tbody>
                {userInfo.subscriptions.map((subscription, index) => (
                    <tr key={index} className={"dashboard-tr"}>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>{subscription.id}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>{subscription.name}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>{subscription.description}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>{unixTimestampToDate(subscription.startDate)}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>{subscription.active ? 'Active' : 'Inactive'}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/admin/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>${subscription.amount}</a>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

    );

    const paymentMethodContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Payment Method</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-25-percent"}>Number</th>
                    <th className={"th-width-25-percent"}>Expiration</th>
                    <th className={"th-width-25-percent"}>Network</th>
                    <th className={"th-width-25-percent"}>ID</th>
                </tr>
                </thead>
                <tbody>
                <tr className={"dashboard-tr"}>
                    <td className={"dashboard-td"}>{userInfo.paymentMethod?.lastFour}</td>
                    <td className={"dashboard-td"}>{userInfo.paymentMethod?.expMonth}/{userInfo.paymentMethod?.expYear}</td>
                    <td className={"dashboard-td"}>{userInfo.paymentMethod?.network}</td>
                    <td className={"dashboard-td"}>{userInfo.paymentMethod?.id}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );


    const [dashboardBorder, setDashboardBorder] = useState(border);
    const [subscriptionsBorder, setSubscriptionsBorder] = useState(none);
    const [paymentLinksBorder, setPaymentLinksBorder] = useState(none);
    const [allPaymentsBorder, setAllPaymentsBorder] = useState(none);
    const [paymentMethodBorder, setPaymentMethodBorder] = useState(none);

    function handleDashboardEntryClick(entry) {
        if (entry === 'dashboard') {
            setDashboardBorder(border);
            setSubscriptionsBorder(none);
            setPaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setPaymentMethodBorder(none);
            setUserDashboardContent(userContent);
        } else if (entry === 'subscriptions') {
            setDashboardBorder(none);
            setSubscriptionsBorder(border);
            setPaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setPaymentMethodBorder(none);
            setUserDashboardContent(subscriptionsContent);
        } else if (entry === 'paymentLinks') {
            setDashboardBorder(none);
            setSubscriptionsBorder(none);
            setPaymentLinksBorder(border);
            setAllPaymentsBorder(none);
            setPaymentMethodBorder(none);
            setUserDashboardContent(paymentLinksContent);
        } else if (entry === 'allPayments') {
            setDashboardBorder(none);
            setSubscriptionsBorder(none);
            setPaymentLinksBorder(none);
            setAllPaymentsBorder(border);
            setPaymentMethodBorder(none);
            setUserDashboardContent(chargesContent);
        } else if (entry === 'paymentMethod') {
            setDashboardBorder(none);
            setSubscriptionsBorder(none);
            setPaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setPaymentMethodBorder(border);
            setUserDashboardContent(paymentMethodContent);
        }
    }

    const [userDashboardContent, setUserDashboardContent] = useState(userContent)


    const userDashboardBody = (
        <>
            <div className={"admin-dashboard-entries"}>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => handleDashboardEntryClick('dashboard')}
                     style={{border: dashboardBorder}}>Dashboard
                </div>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => handleDashboardEntryClick('subscriptions')}
                     style={{border: subscriptionsBorder}}>Subscriptions
                </div>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => handleDashboardEntryClick('paymentLinks')}
                     style={{border: paymentLinksBorder}}>Payment Links
                </div>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => handleDashboardEntryClick('allPayments')}
                     style={{border: allPaymentsBorder}}>All Payments
                </div>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => handleDashboardEntryClick('paymentMethod')}
                     style={{border: paymentMethodBorder}}>Payment Method
                </div>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => navigate("/admin")}>Account
                </div>
            </div>
            {
                userError ?
                    <div>Error loading user dashboard content</div>
                    :
                    userDashboardContent
            }
        </>
    );


    return (
        <div className={"admin page"}>
            <Header/>
            <div className={"small-heading"}>User</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"}>
                    {loading ?
                        <Loading text={"Fetching User Info"}/>
                        :
                        userError ?
                            <div>Error loading user content</div>
                            :
                            userDashboardBody
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AdminUser;