import Header from "./Header";
import bannerVideo from '../videos/BannerVideo.mp4';
import leftHand from '../images/hand-left.png';
import rightHand from '../images/hand-right.png';
import twoRobots from '../images/two-robots.png';
import computer from '../images/computer.png';
import largeComputer from '../images/LargeComputer.png';
import dollarSign from '../images/dollar-sign.png';
import explainerPoster from '../images/explainer-video-poster.jpeg';
import explainerVideo from '../videos/ExplainerVideo.mp4';
import {useRef, useState} from "react";
import ContactForm from "./ContactForm";
import Footer from "./Footer";
import LazyLoad from "./LazyLoad";
import {isUserLoggedIn} from "../service/AuthService";

const purple = "#b619ff";

function Home() {

    const bannerVideoRef = useRef(null);

    const [loggedIn, setLoggedIn] = useState(isUserLoggedIn);

    function playBannerVideo() {
        bannerVideoRef.current.play();
    }

    function pauseBannerVideo() {
        bannerVideoRef.current.pause();
    }

    const [playButtonVisibility, setPlayButtonVisibility] = useState("visible");
    const [explainerVideoControls, setExplainerVideoControls] = useState(false);

    const explainerVideoRef = useRef(null);

    function playExplainerVideo() {
        explainerVideoRef.current.play();
        setPlayButtonVisibility("hidden");
        setExplainerVideoControls(true);
    }

    function reloadExplainerVideo() {
        explainerVideoRef.current.load();
        setPlayButtonVisibility("visible");
        setExplainerVideoControls(false);
    }


    const [headingVisibility, setHeadingVisibility] = useState("visible");
    const [automateVisibility, setAutomateVisibility] = useState("visible");
    const [videoVisibility, setVideoVisibility] = useState("hidden");
    const [leftHandClass, setLeftHandClass] = useState("");
    const [rightHandClass, setRightHandClass] = useState("");
    const [leftButtonBackground, setLeftButtonBackground] = useState(purple);
    const [rightButtonBackground, setRightButtonBackground] = useState("none");

    function handleSwitchButtonClick(direction) {
        if (direction === "left") {
            setLeftButtonBackground(purple);
            setRightButtonBackground("none");
            resetVideoHeading()
        } else {
            setLeftButtonBackground("none");
            setRightButtonBackground(purple);
            handlePlayButtonClick();
        }

    }


    function handlePlayButtonClick() {
        setLeftHandClass("slide-out-left");
        setRightHandClass("slide-out-right");
        setTimeout(() => {
            setLeftHandClass('');
            setRightHandClass('');
            setHeadingVisibility("hidden");
            setAutomateVisibility("hidden");
            setVideoVisibility("visible");
            playBannerVideo();
        }, 500);
    }

    function resetVideoHeading() {
        setHeadingVisibility(headingVisibility === "visible" ? "hidden" : "visible");
        setAutomateVisibility(automateVisibility === "visible" ? "hidden" : "visible");
        setVideoVisibility(videoVisibility === "visible" ? "hidden" : "visible");
        pauseBannerVideo();
    }

    return (
        <div className={"home page"}>
            <Header headingVisibility={headingVisibility} home={true} setLoggedIn={setLoggedIn} loggedIn={loggedIn}/>
            <div className={"content"}>
                <div className={"video-section"}>
                    <div className={"not-playing"} style={{visibility: automateVisibility}}>
                        <div className={"automate-your-life"}>
                            <div className={"automate slide-in-left"}>AUTOMATE</div>
                            <div className={"your-life slide-in-right"}>YOUR LIFE</div>
                        </div>
                        <div className={"hands"}>
                            <img className={"left-hand " + leftHandClass} src={leftHand} alt={"left-hand"}/>
                            <img className={"right-hand " + rightHandClass} src={rightHand} alt={"right-hand"}/>
                        </div>
                        <div className={"play-button"} onClick={() => handleSwitchButtonClick("right")}>
                            <div className="triangle"></div>
                        </div>
                    </div>
                    <div className={"banner-video-wrapper"} style={{visibility: videoVisibility}}>
                        <video className={"banner-video"} ref={bannerVideoRef} playsInline controls
                               onEnded={() => handleSwitchButtonClick("left")}>
                            <source src={bannerVideo} type="video/mp4"/>
                        </video>
                    </div>
                    <div className={"switch-buttons-wrapper"}>
                        <div className={"switch-buttons"}>
                            <button className={"switch-button switch-button-left"}
                                    style={{background: leftButtonBackground}}
                                    onClick={() => handleSwitchButtonClick("left")}></button>
                            <button className={"switch-button switch-button-right"}
                                    style={{background: rightButtonBackground}}
                                    onClick={() => handleSwitchButtonClick("right")}></button>
                        </div>
                    </div>
                </div>
                <div className={"home-page-text"}>
                    <div className={"section enclosed home-section-1 row"}>
                        <div className={"half-section"}>
                            <LazyLoad>
                                <img src={twoRobots} alt={"two-robots"} className={"enclosed-image scale-up"}/>
                            </LazyLoad>
                        </div>
                        <div className={"half-section slide-in-right"}>
                            <LazyLoad>
                                <div className={"slide-in-right"}>
                                    <div className={"section-heading section-heading-enclosed"}>Streamline Every Task
                                        with Automation
                                    </div>
                                    <p className={"section-content"}>At Rexius Algorithms, we specialize in providing
                                        automation
                                        services that save you time and increase productivity. From daily tasks to
                                        stocks,
                                        we
                                        can streamline your every task.</p>
                                    <div className={"learn-more-wrapper"}>

                                        <a href="/about" className="learn-more">Learn More</a>
                                    </div>
                                </div>
                            </LazyLoad>
                        </div>
                    </div>
                    <div className={"section open center"}>
                        <LazyLoad>
                            <div className={"section-heading slide-in-top"}>About Us</div>
                        </LazyLoad>
                        <LazyLoad>
                            <p className={"section-content slide-in-top"}>Rexius Algorithms was born from the innovative
                                vision of
                                its
                                founder, Brett Rexius, in 2023. He initially developed an algorithm to efficiently
                                gather
                                weather and swell data daily at 6 a.m., subsequently processing it to determine the
                                optimal
                                surfing locations for the day and sending himself an email with this invaluable
                                information.
                                <br/>
                                <br/>
                                Recognizing that this smart automation saved him approximately 2 hours of manual surf
                                report
                                checks each month, he considered extending these time-saving efficiencies to other
                                facets of
                                his life. This journey led him to automate stock and cryptocurrency trading, financial
                                database updates, and many other tasks related to automated stock trading systems.
                                Understanding that not everyone possessed the software engineering expertise required
                                for
                                creating such automated systems, he resolved to make these benefits accessible to
                                others.
                                <br/>
                                <br/>
                                Thus, Rexius Algorithms emerged as a testament to the transformative power of automation
                                and
                                the desire to share its advantages with a broader audience.
                            </p>
                        </LazyLoad>
                    </div>
                    <div className={"section open center"}>
                        <LazyLoad>
                            <div className={"section-heading slide-in-top"}>Our Services</div>
                        </LazyLoad>
                        <LazyLoad>
                            <p className={"section-content slide-in-top"}>
                                We offer a range of automation services, including:
                                <br/>
                                <br/>
                                <span className={"bold"}>Task Automation</span> - Streamline repetitive tasks,
                                liberating
                                your time for more critical
                                activities.
                                <br/>
                                <br/>
                                <span className={"bold"}>Communication Automation</span> - Optimize outreach efforts,
                                ensuring timely and effective
                                interactions.
                                <br/>
                                <br/>
                                <span className={"bold"}>Financial Automation</span> - Get detailed financial data
                                reports
                                with an automated system.
                                <br/>
                                <br/>
                                <span className={"bold"}>Workflow Automation</span> - Maximize operational efficiency by
                                automating your workflows.
                                <br/>
                                <br/>
                                <span className={"bold"}>Custom Automation Solutions</span> - Tailored solutions for
                                routine
                                task automation and financial
                                data reports.
                            </p>
                        </LazyLoad>
                    </div>
                    <div className={"section enclosed row"}>
                        <div className={"half-section"}>
                            <LazyLoad>
                                <div className={"slide-in-left"}>
                                    <div className={"section-heading section-heading-enclosed mobile-margin-top-0"}>Why Choose Automation?
                                    </div>
                                    <p className={"section-content"}>It is a smart move toward maximizing productivity,
                                        especially for managing daily tasks. By automating routine tasks, you free up
                                        valuable
                                        time to focus on high-value, strategic endeavors.</p>
                                    <div className={"learn-more-wrapper"}>
                                        <a href="/about" className="learn-more">Learn More</a>
                                    </div>
                                </div>
                            </LazyLoad>
                        </div>
                        <div className={"half-section"}>
                            <LazyLoad>
                                <img src={computer} alt={"computer"} className={"enclosed-image scale-up"}/>
                            </LazyLoad>
                        </div>
                    </div>
                    <div className={"section open center"}>
                        <LazyLoad>
                            <div className={"section-heading slide-in-top"}>Daily Email and Report Solutions for All
                            </div>
                        </LazyLoad>
                        <LazyLoad>
                            <p className={"section-content slide-in-top"}>
                                <span className={"bold"}>Proactive Insights</span> - Regular email and report solutions
                                offer invaluable advantages for both businesses and individuals. Firstly, we provide
                                timely
                                insights into key metrics and performance indicators. By receiving regular updates, you
                                can
                                stay well-informed about the status of various operations, allowing for faster responses
                                to
                                emerging trends or issues. <br/>
                                <br/>
                                <span className={"bold"}>Enhanced Communication Efficiency</span> - Daily emails and
                                reports
                                enhance
                                communication efficiency. Instead of sifting through multiple sources of information,
                                critical financial data is consolidated into a single, easily understandable format.
                                This
                                saves time and reduces the chances of overlooking important details. It fosters a
                                streamlined workflow, enabling you to focus on high-priority tasks rather than getting
                                mixed
                                up by gathering and analyzing financial data.
                                activities.
                                <br/>
                                <br/>
                                <span className={"bold"}>Issue Identification and Resolution</span> - Daily reports can
                                flag
                                potential
                                challenges or discrepancies promptly. This allows immediate action, preventing minor
                                problems from escalating into larger, more complex issues. By addressing concerns in
                                real
                                time, you can maintain a smooth operational flow and ensure the reliability and
                                compliance
                                of your work processes.
                            </p>
                        </LazyLoad>
                    </div>
                    <div className={"section open center"}>
                        <LazyLoad>
                            <img src={largeComputer} alt={"computer"} className={"enclosed-image scale-up"}/>
                        </LazyLoad>
                    </div>
                    <div className={"explainer-video-wrapper"}>
                        <div className={"explainer-video-play-button"} onClick={() => playExplainerVideo()}
                             style={{visibility: playButtonVisibility}}>
                            <div className="triangle"></div>
                        </div>
                        <video className={"explainer-video"} ref={explainerVideoRef} playsInline
                               controls={explainerVideoControls}
                               poster={explainerPoster}
                               onEnded={() => reloadExplainerVideo()}>
                            <source src={explainerVideo} type="video/mp4"/>
                        </video>
                    </div>
                    <div className={"section enclosed row"}>
                        <div className={"half-section"}>
                            <LazyLoad>
                                <div className={"slide-in-left"}>
                                    <div className={"section-heading section-heading-enclosed mobile-margin-top-0"}>Pricing</div>
                                    <p className={"section-content"}>Automate your processes with fair pricing as low as
                                        $10/month. Enhance the efficiency of your operations with Rexius Algorithms
                                        today!</p>
                                    <div className={"learn-more-wrapper"}>
                                        <a href="/pricing" className="learn-more">Learn More</a>
                                    </div>
                                </div>
                            </LazyLoad>
                        </div>
                        <div className={"half-section"}>
                            <LazyLoad>
                                <img src={dollarSign} alt={"computer"} className={"enclosed-image scale-up"}/>
                            </LazyLoad>
                        </div>
                    </div>
                    <ContactForm/>
                    <Footer/>
                </div>
            </div>
        </div>


    )

}

export default Home;