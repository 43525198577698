import Header from "./Header";
import Footer from "./Footer";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {forgotPassword} from "../service/ResetPasswordService";
import Loading from "./Loading";

function ForgotPassword() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            forgotPassword(email)
                .then(forgotEmailResponse => {
                    if (forgotEmailResponse === true) {
                        setSent(true);
                    } else {
                        window.alert("Could not send reset email.")
                    }
                    setLoading(false)
                    // navigate("/account");
                })
                .catch(error => {
                    console.error("Error logging in:", error);
                    setLoading(false);
                });

        } catch (error) {
            console.error('Login failed:', error);
            setLoading(false);
        }
    };

    const sentForm = (
        <div className="content">
            <div className={"no-account"}>An email has been sent to {email}.</div>
            <div className={"no-account"}>If you cannot find it, be sure to check your spam folder.</div>
            <br/>
            <div className={"click-here"} onClick={() => navigate("/login")}>Log in</div>
            <div className={"click-here"} onClick={() => navigate("/")}>Go to main page</div>
            <a href={"/forgot-password"} className={"click-here"}>Go back</a>
        </div>
    )

    const forgotPasswordForm = (
        <form onSubmit={handleSubmit} className={"slide-in-right"}>
            <input className={"contact-form-input"}
                   required
                   type={"email"}
                   placeholder={"Email"}
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
            />
            <div className={"send-wrapper mobile-margin-bottom-0"}>
                <button type={"submit"} className="send">Submit</button>
            </div>
            <div className={"have-account"}>
                <a href={"/forgot-password"} className={"click-here"}>Go back</a>
            </div>

        </form>
    )


    return (
        <div className={"login page"}>
            <Header/>
            <div className={"page-heading"}>PASSWORD</div>
            <div className={"content"}>
                <div className={"section enclosed row"}>
                    <div className={"half-section login-form"}>
                        <div className={"section-heading"}>Forgot Password</div>
                        {loading ? <Loading text={"Sending Reset Email"}/>
                            :
                            !sent ?
                                forgotPasswordForm
                                :
                                sentForm
                        }

                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ForgotPassword;