import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {createPaymentLink, createSubscription, fetchAdminDashboardInfo} from "../service/AdminService";
import Loading from "./Loading";
import {unixTimestampToDate} from "../service/DateService";
import {useNavigate} from "react-router-dom";

const border = 'solid 2px #b619ff';
const none = 'none'

function AdminDashboard() {

    const [toggle, setToggle] = useState(true);


    const [adminDashboardInfo, setAdminDashboardInfo] = useState({
        "subscriptions": [
            {
                // id: "14_1078920500",
                customer: "Abel Williamson",
                name: "test1",
                amount: 10,
                startDate: "2023-10-03"
            },
            {
                // id: "14_216115403",
                customer: "Abel Williamson",
                name: "test2",
                amount: 15,
                startDate: "2023-10-03"
            }
        ],
        "paymentLinks": [
            {
                customer: 'Abel Williamson',
                name: 'abel.williamson@websitedezk.com',
                amount: 10,
                created: '2023-10-03',
                paid: false
            }
        ],
        "charges": [
            {
                customer: 'Abel Williamson',
                name: 'abel.williamson@websitedezk.com',
                amount: 10,
                date: '2023-10-03',
            }
        ],
        "adminUserResponses": [
            {
                "id": 8,
                "firstName": "Brett",
                "lastName": "Rexius",
                "email": "brettrexius@gmail.com",
                "dateCreated": 1712293137751,
                "stripeId": "cus_ProUPPtpwXvTWX",
                "stripeInvoicePrefix": "C759B3A1",
                "subscriptions": [],
                "paymentLinks": []
            },
            {
                "id": 9,
                "firstName": "Brett",
                "lastName": "Rexius",
                "email": "brett.rexius@rexiusalgorithms.com",
                "dateCreated": 1712460725339,
                "stripeId": "cus_PsXXO4XJkTghck",
                "stripeInvoicePrefix": "6F05DDE3",
                "subscriptions": [],
                "paymentLinks": []
            }
        ],
        "chargesTotal": 10

    });
    const [adminDashboardError, setAdminDashboardError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [creatingSubscription, setCreatingSubscription] = useState(false);
    const [creatingPaymentLink, setCreatingPaymentLink] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem("raAccessToken")) {
            navigate("/login");
        }
        try {
            setLoading(true)
            fetchAdminDashboardInfo(true)
                .then(adminDashboardResponse => {
                    setAdminDashboardInfo(adminDashboardResponse);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading admin dashboard:", error);
                    setAdminDashboardError(true);
                    setLoading(false);
                });

        } catch (error) {
            console.error("Error loading admin dashboard:", error);
            setAdminDashboardError(true);
            setLoading(false);
        }
    }, [toggle]);

    useEffect(() => {
        setAdminDashboardContent(dashboardContent); // This sets the initial content

    }, [adminDashboardInfo]); // Re-run effect when adminDashboardInfo changes


    const [dashboardBorder, setDashboardBorder] = useState(border);
    const [usersBorder, setUsersBorder] = useState(none);
    const [subscriptionsBorder, setSubscriptionsBorder] = useState(none);
    const [createSubscriptionBorder, setCreateSubscriptionBorder] = useState(none);
    const [paymentLinksBorder, setPaymentLinksBorder] = useState(none);
    const [createPaymentLinksBorder, setCreatePaymentLinksBorder] = useState(none);
    const [allPaymentsBorder, setAllPaymentsBorder] = useState(none);

    const dashboardContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Dashboard</div>
            <div className={"dashboard-number-ofs"}>
                <div className={"dashboard-number-of"} onClick={() => handleDashboardEntryClick('users')}>
                    <div className={"dashboard-number-of-content"}>Users</div>
                    <div className={"dashboard-number-of-content"}>{adminDashboardInfo.adminUserResponses.length}</div>
                </div>
                <div className={"dashboard-number-of"} onClick={() => handleDashboardEntryClick('subscriptions')}>
                    <div className={"dashboard-number-of-content"}>Subscriptions</div>
                    <div className={"dashboard-number-of-content"}>{adminDashboardInfo.subscriptions.length}</div>
                </div>
                <div className={"dashboard-number-of"} onClick={() => handleDashboardEntryClick('paymentLinks')}>
                    <div className={"dashboard-number-of-content"}>Payment Links</div>
                    <div className={"dashboard-number-of-content"}>{adminDashboardInfo.paymentLinks.length}</div>
                </div>
                <div className={"dashboard-number-of"} onClick={() => handleDashboardEntryClick('allPayments')}>
                    <div className={"dashboard-number-of-content"}>Payments</div>
                    <div className={"dashboard-number-of-content"}>${adminDashboardInfo.chargesTotal}</div>
                </div>
            </div>
        </div>
    )


    const usersContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Users</div>

            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-25-percent"}>Name</th>
                    <th className={"th-width-40-percent"}>Email</th>
                    <th className={"th-width-25-percent"}>Date</th>
                </tr>
                </thead>
                <tbody>
                {adminDashboardInfo.adminUserResponses.map((user) => (
                    <tr key={user.id} className={"dashboard-tr"}>
                        <td className="user-details"><div className={"dashboard-td-inner"}><a href={"admin/user/" + user.id}
                                                        className={"dashboard-link"}>{user.firstName} {user.lastName}</a></div>
                        </td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"admin/user/" + user.id} className={"dashboard-link"}>{user.email}</a></div></td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"admin/user/" + user.id}
                               className={"dashboard-link"}>{unixTimestampToDate(user.dateCreated / 1000)}</a></div></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );


    const handleCreateSubscription = async (e, email, name, charge, date, description) => {
        e.preventDefault();
        setCreatingSubscription(true);
        try {
            createSubscription(true, email, name, charge, date, description)
                .then(createSubscriptionResponse => {
                    if (createSubscriptionResponse === true) {
                        window.alert("Subscription created!");
                        setToggle(!toggle);
                        handleDashboardEntryClick('dashboard')
                        setAdminDashboardContent(dashboardContent);
                    } else {
                        window.alert("Could not create subscription")
                    }
                    setCreatingSubscription(false);
                })
                .catch(error => {
                    console.error("Error creating subscription:", error);
                    window.alert('Could not create subscription')
                    setCreatingSubscription(false);
                });
        } catch (error) {
            console.error("Error creating subscription:", error);
            window.alert('Could not create subscription')
            setCreatingSubscription(false);
        }
    }

    const createSubscriptionContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Create Subscription</div>
            <form onSubmit={(e) => handleCreateSubscription(e,
                document.getElementById("create-subscription-email").value,
                document.getElementById("create-subscription-name").value,
                document.getElementById("create-subscription-charge").value,
                document.getElementById("create-subscription-date").value,
                document.getElementById("create-subscription-description").value)}
                  className={"create-subscription-form"}>
                <select id={"create-subscription-email"} className={"create-subscription-input"}>
                    {adminDashboardInfo.adminUserResponses.map((user) => (
                        <option key={user.id} className={"user-option"}>{user.email}</option>
                    ))}
                </select>
                <input id={"create-subscription-name"} className={"create-subscription-input"} placeholder={"Name"}
                       type="text"/>
                <input id={"create-subscription-charge"} className={"create-subscription-input"}
                       placeholder={"Monthly Charge"} type="number"/>
                <input id={"create-subscription-date"} className={"create-subscription-input"} type="date"
                       required/>
                <i className="fas fa-calendar-alt calendar-icon"></i>
                <textarea id={"create-subscription-description"} className={"create-subscription-text-area"}
                          placeholder="Description"></textarea>
                <div className={"send-wrapper"}>
                    <button type={"submit"} className="send">Create</button>
                </div>
            </form>
        </div>
    );

    const subscriptionsContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Subscriptions</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    {/*<th>ID</th>*/}
                    <th>Client</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                {adminDashboardInfo.subscriptions.map((subscription, index) => (
                    <tr key={index} className={"dashboard-tr"}>
                        {/*<td className={"dashboard-td"}>{subscription.id}</td>*/}
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/user/" + subscription.userId}
                               className={"dashboard-link"}>{subscription.firstName} {subscription.lastName}</a></div></td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/subscription/" + subscription.id}
                               className={"dashboard-link"}>{subscription.name}</a></div></td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/subscription/" + subscription.id}
                               className={"dashboard-link"}>{unixTimestampToDate(subscription.startDate)}</a></div></td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/subscription/" + subscription.id}
                               className={"dashboard-link"}>${subscription.amount}</a></div></td>
                    </tr>
                    // <tr key={index} className={"dashboard-tr"}>
                    //     <td className={"dashboard-td"}>
                    //         <a href={`https://rexiusalgorithms.com/packages/${subscription.no}/`}>{subscription.no}</a></div>
                    //     </td>
                    //     <td className={"dashboard-td"}>{subscription.name}</td>
                    //     <td className={"dashboard-td"}>
                    //         <a href={`mailto:${subscription.clientEmail}`}>{subscription.clientName}</a></div>
                    //     </td>
                    //     <td className={"dashboard-td"}>{subscription.date}</td>
                    //     <td className={"dashboard-td"}>
                    //         <span>{subscription.status}</span>
                    //     </td>
                    // </tr>
                ))}
                </tbody>
            </table>
        </div>
    )

    const handleCreatePaymentLink = async (e, email, name, charge, date, description) => {
        e.preventDefault();
        setCreatingPaymentLink(true);
        try {
            createPaymentLink(true, email, name, charge, date, description)
                .then(createSubscriptionResponse => {
                    if (createSubscriptionResponse === true) {
                        window.alert("Payment link created!");
                        setToggle(!toggle);
                        handleDashboardEntryClick('dashboard')
                        setAdminDashboardContent(dashboardContent);
                    } else {
                        window.alert("Could not create payment link")
                    }
                    setCreatingPaymentLink(false);
                })
                .catch(error => {
                    console.error("Error creating payment link:", error);
                    window.alert('Could not create payment link')
                    setCreatingPaymentLink(false);
                });
        } catch (error) {
            console.error("Error creating payment link:", error);
            window.alert('Could not create payment link')
            setCreatingPaymentLink(false);
        }
    }

    const createPaymentLinkContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Create Payment Link</div>
            <form
                onSubmit={(e) => handleCreatePaymentLink(e,
                    document.getElementById("create-payment-link-email").value,
                    document.getElementById("create-payment-link-name").value,
                    document.getElementById("create-payment-link-charge").value,
                    document.getElementById("create-payment-link-date").value,
                    document.getElementById("create-payment-link-description").value)}
                className={"create-subscription-form"}>
                <select id={"create-payment-link-email"} className={"create-subscription-input"}>
                    {adminDashboardInfo.adminUserResponses.map((user) => (
                        <option key={user.id} className={"user-option"}>{user.email}</option>
                    ))}
                </select>
                <input id={"create-payment-link-name"} className={"create-subscription-input"} placeholder={"Name"}
                       type="text"/>
                <input id={"create-payment-link-charge"} className={"create-subscription-input"} placeholder={"Charge"}
                       type="number"/>
                <input id={"create-payment-link-date"} className={"create-subscription-input"} type="date" required/>
                <textarea id={"create-payment-link-description"} className={"create-subscription-text-area"}
                          placeholder="Description"></textarea>
                <div className={"send-wrapper"}>
                    <button type={"submit"} className="send">Create</button>
                </div>
            </form>
        </div>
    )

    const paymentLinksContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Payment Links</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th>Client</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {adminDashboardInfo.paymentLinks.map((link, index) => (
                    <tr key={index} className={"dashboard-tr"}>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/user/" + link.customerId}
                               className={"dashboard-link"}>{link.firstName} {link.lastName}</a></div></td>
                        {/*<td className={"dashboard-td"}><span style={{color: 'green'}}>{link.status}</span></td>*/}
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/payment-link/" + link.id} className={"dashboard-link"}>{link.name}</a></div></td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/payment-link/" + link.id}
                               className={"dashboard-link"}>{unixTimestampToDate(link.created)}</a></div></td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/payment-link/" + link.id} className={"dashboard-link"}>${link.amount}</a></div>
                        </td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/payment-link/" + link.id} className={"dashboard-link"}>{link.paid === true ? "Paid" : "Active"}</a></div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )

    const allPaymentsContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>All Payments</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-25-percent"}>ID</th>
                    <th className={"th-width-25-percent"}>Client</th>
                    <th className={"th-width-25-percent"}>Date</th>
                    <th className={"th-width-25-percent"}>Amount</th>
                </tr>
                </thead>
                <tbody>
                {adminDashboardInfo.charges.map((payment, index) => (
                    <tr key={index} className={"dashboard-tr"}>
                        {/*<td className={"dashboard-td"}><a href={`https://rexiusalgorithms.com/packages/${payment.no}/`}>{payment.no}</a></div></td>*/}
                        {/*<td className={"dashboard-td"}><a href={`mailto:${payment.clientEmail}`}>{payment.clientName}</a></div></td>*/}
                        {/*<td className={"dashboard-td"}><span style={{color: payment.statusColor}}>{payment.status}</span></td>*/}
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/charge/" + payment.id} className={"dashboard-link"}>{payment.id}</a></div></td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/user/" + payment.userId}
                               className={"dashboard-link"}>{payment.firstName} {payment.lastName}</a></div></td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/charge/" + payment.id}
                               className={"dashboard-link"}>{unixTimestampToDate(payment.date)}</a></div></td>
                        <td className={"dashboard-td"}><div className={"dashboard-td-inner"}><a href={"/admin/charge/" + payment.id}
                               className={"dashboard-link"}>${payment.amount}</a></div></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )

    const [adminDashboardContent, setAdminDashboardContent] = useState(dashboardContent);

    function handleDashboardEntryClick(entry) {
        if (entry === 'dashboard') {
            setDashboardBorder(border);
            setUsersBorder(none);
            setSubscriptionsBorder(none);
            setCreateSubscriptionBorder(none);
            setPaymentLinksBorder(none);
            setCreatePaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setAdminDashboardContent(dashboardContent);
        } else if (entry === 'users') {
            setDashboardBorder(none);
            setUsersBorder(border);
            setSubscriptionsBorder(none);
            setCreateSubscriptionBorder(none);
            setPaymentLinksBorder(none);
            setCreatePaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setAdminDashboardContent(usersContent);
        } else if (entry === 'subscriptions') {
            setDashboardBorder(none);
            setUsersBorder(none);
            setSubscriptionsBorder(border);
            setCreateSubscriptionBorder(none);
            setPaymentLinksBorder(none);
            setCreatePaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setAdminDashboardContent(subscriptionsContent);
        } else if (entry === 'createSubscription') {
            setDashboardBorder(none);
            setUsersBorder(none);
            setSubscriptionsBorder(none);
            setCreateSubscriptionBorder(border);
            setPaymentLinksBorder(none);
            setCreatePaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setAdminDashboardContent(createSubscriptionContent);
        } else if (entry === 'paymentLinks') {
            setDashboardBorder(none);
            setUsersBorder(none);
            setSubscriptionsBorder(none);
            setCreateSubscriptionBorder(none);
            setPaymentLinksBorder(border);
            setCreatePaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setAdminDashboardContent(paymentLinksContent);
        } else if (entry === 'createPaymentLink') {
            setDashboardBorder(none);
            setUsersBorder(none);
            setSubscriptionsBorder(none);
            setCreateSubscriptionBorder(none);
            setPaymentLinksBorder(none);
            setCreatePaymentLinksBorder(border);
            setAllPaymentsBorder(none);
            setAdminDashboardContent(createPaymentLinkContent);
        } else if (entry === 'allPayments') {
            setDashboardBorder(none);
            setUsersBorder(none);
            setSubscriptionsBorder(none);
            setCreateSubscriptionBorder(none);
            setPaymentLinksBorder(none);
            setCreatePaymentLinksBorder(none);
            setAllPaymentsBorder(border);
            setAdminDashboardContent(allPaymentsContent);
        }
    }

    const adminDashboardBody = (
            <>
                <div className={"admin-dashboard-entries"}>
                    <div className={"admin-dashboard-entry dashboard-link"}
                         onClick={() => handleDashboardEntryClick('dashboard')}
                         style={{border: dashboardBorder}}>Dashboard
                    </div>
                    <div className={"admin-dashboard-entry dashboard-link"}
                         onClick={() => handleDashboardEntryClick('users')}
                         style={{border: usersBorder}}>Users
                    </div>
                    <div className={"admin-dashboard-entry dashboard-link"}
                         onClick={() => handleDashboardEntryClick('subscriptions')}
                         style={{border: subscriptionsBorder}}>Subscriptions
                    </div>
                    <div className={"admin-dashboard-entry dashboard-link"}
                         onClick={() => handleDashboardEntryClick('createSubscription')}
                         style={{border: createSubscriptionBorder}}>Create Subscription
                    </div>
                    <div className={"admin-dashboard-entry dashboard-link"}
                         onClick={() => handleDashboardEntryClick('paymentLinks')}
                         style={{border: paymentLinksBorder}}>Payment Links
                    </div>
                    <div className={"admin-dashboard-entry dashboard-link"}
                         onClick={() => handleDashboardEntryClick('createPaymentLink')}
                         style={{border: createPaymentLinksBorder}}>Create Payment Link
                    </div>
                    <div className={"admin-dashboard-entry dashboard-link"}
                         onClick={() => handleDashboardEntryClick('allPayments')}
                         style={{border: allPaymentsBorder}}>All Payments
                    </div>
                </div>
                {
                    adminDashboardError ?
                        <div>Error loading admin dashboard content</div>
                        :
                        adminDashboardContent
                }
            </>
        )
    ;

    return (
        <div className={"admin page"}>
            <Header/>
            <div className={"page-heading"}>ADMIN</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"}>
                    {loading ?
                        <Loading text={"Fetching Admin Dashboard Info"}/>
                        :
                        creatingSubscription ?
                            <Loading text={"Creating subscription"}/>
                            :
                            creatingPaymentLink ?
                                <Loading text={"Creating payment link"}/>
                                :
                                adminDashboardBody
                    }

                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AdminDashboard;