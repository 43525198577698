import {refreshToken} from "./AuthService";
import {properties} from "../properties";

export function changePassword(firstCall, currentPassword, newPassword, confirmationPassword) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('raAccessToken').replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem('raAccessToken', JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        changePassword(false, currentPassword, newPassword, confirmationPassword).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem("raAccessToken");
                        localStorage.removeItem("raRefreshToken");
                        reject(new Error("Failed to refresh token."));
                    });
                } else {
                    reject(new Error("Failed to change password."));
                }
            }
        };
        xhttp.open("POST", properties.host + "/api/v1/users/changepassword", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);
        const request = {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmationPassword: confirmationPassword
        };
        xhttp.send(JSON.stringify(request));
    });
}