import Header from "./Header";
import ContactForm from "./ContactForm";
import Footer from "./Footer";
import task from '../images/task-automation.png';
import communication from '../images/communication-automation.png';
import financial from '../images/financial-automation.png';
import workflow from '../images/workflow-automation.png';
import custom from '../images/custom-automation.png';
import LazyLoad from "./LazyLoad";

function Services() {

    return (
        <div className={"services page"}>
            <Header/>
            <div className={"page-heading"}>SERVICES</div>
            <div className={"content"}>
                <div className={"services-page-text"}>
                    <div className={"section open"}>
                        <p className={"section-content"}>
                            Rexius Algorithms offers various automation services designed to enhance your business’
                            efficiency by streamlining daily tasks. This encompasses scheduling tasks, managing your
                            server,
                            and executing pre-programmed actions.
                            <br/>
                            Our technology-driven solutions can swiftly collect data, generate detailed reports, and
                            send
                            you tailored emails with the precise information you require, all while optimizing
                            efficiency
                            and productivity. We also take care of hosting and maintenance, ensuring seamless operations
                            without the need for constant manual oversight.
                            <br/>
                            Here are the services we offer:
                        </p>
                    </div>
                    <div className={"section open"}>
                        <div className={"services-section"}>
                            <div className={"service-section scale-up"}>
                                <img className={"service-section-service-image "} src={task} alt={"task-automation"}/>
                                <div className={"service-section-task-name"}>Task Automation</div>
                                <div className={"service-section-learn-more"}>
                                    <a href="/services/task" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"service-section scale-up"}>
                                <img className={"service-section-service-image enclosed-image"} src={communication}
                                     alt={"communication-automation"}/>
                                <div className={"service-section-task-name"}>Communication Automation</div>
                                <div className={"service-section-learn-more"}>
                                    <a href="/services/communication" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"service-section scale-up"}>
                                <img className={"service-section-service-image enclosed-image"} src={financial}
                                     alt={"financial-automation"}/>
                                <div className={"service-section-task-name"}>Financial Automation</div>
                                <div className={"service-section-learn-more"}>
                                    <a href="/services/financial" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"service-section scale-up"}>
                                <img className={"service-section-service-image enclosed-image"} src={workflow}
                                     alt={"workflow-automation"}/>
                                <div className={"service-section-task-name"}>Workflow Automation</div>
                                <div className={"service-section-learn-more"}>
                                    <a href="/services/workflow" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"service-section scale-up"}>
                                <img className={"service-section-service-image enclosed-image"} src={custom}
                                     alt={"custom-automation"}/>
                                <div className={"service-section-task-name"}>Custom Automation</div>
                                <div className={"service-section-learn-more"}>
                                    <a href="/services/custom" className="learn-more">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LazyLoad>
                    <ContactForm/>
                </LazyLoad>
            </div>
            <Footer/>
        </div>
    )

}

export default Services;