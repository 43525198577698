import React, { useEffect, useRef, useState } from 'react';
import Loading from "./Loading";

const LazyLoad = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const hasBeenVisible = useRef(false); // Track if the component has ever been visible
    const domRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !hasBeenVisible.current) {
                    setIsVisible(true);
                    hasBeenVisible.current = true; // Mark as visible
                    observer.unobserve(entry.target); // Stop observing
                }
            });
        });

        const currentElement = domRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, []);

    return (
        <div ref={domRef}>
            {isVisible ? children : <div></div>}
        </div>
    );
};

export default LazyLoad;