import Header from "./Header";
import Footer from "./Footer";
import ContactForm from "./ContactForm";
import LazyLoad from "./LazyLoad";
import twoRobots from "../images/two-robots.png";

function Pricing() {

    return (
        <div className={"services page"}>
            <Header/>
            <div className={"page-heading"}>PRICING</div>
            <div className={"content"}>
                <div className={"pricing-page-text"}>
                    <div className={"section open"}>
                        <p className={"section-content"}>
                            At Rexius Algorithms, we understand that each automation project is unique in its complexity
                            and requirements. To ensure a fair and transparent pricing structure that aligns with your
                            specific needs, we offer flexible pricing tailored to the size and intricacy of your
                            automation. Our pricing is based on a monthly subscription model, eliminating any upfront
                            costs and providing you with the flexibility to scale your automation as needed. In fact,
                            our complete automation packages start as low as $10 per month.
                        </p>
                    </div>
                    <div className={"section enclosed"}>
                        <div className={"section-heading"}>Pricing Factors</div>
                        <div className={"section-subheading"}>Complexity</div>
                        <p className={"section-content"}>
                            The complexity of your automation project plays a significant role in determining the
                            pricing. More intricate automations may require additional development time and ongoing
                            maintenance, which is factored into the cost.
                        </p>
                        <div className={"section-subheading"}>External Resources</div>
                        <p className={"section-content"}>
                            Depending on your automation requirements, certain integrations or access to external
                            resources, such as paid accounts or APIs, may be necessary. We will guide you through the
                            setup process during our thorough video consultation.
                        </p>
                        <div className={"section-subheading"}>Hosting</div>
                        <p className={"section-content"}>
                            Rest assured, we take care of the hosting for your automation system. Hosting fees can vary
                            depending on factors like instance size, data storage, and data transfer requirements.
                        </p>
                        <div className={"section-heading"}>Pricing Examples
                        </div>
                        <div className={"section-subheading"}>$10/month</div>
                        <p className={"section-content"}>
                            For example, you can enjoy a daily email with personalized weather forecasts, market trends,
                            and local events, or receive text notifications whenever items in your Google Doc go on sale
                            from a retail website.
                        </p>
                        <div className={"section-subheading"}>$15/month</div>
                        <p className={"section-content"}>
                            Opt for a daily email to clients extracted from your Google Sheet, or stay updated on
                            unusual real estate trends in your area.
                        </p>
                        <div className={"section-subheading"}>$20/month</div>
                        <p className={"section-content"}>
                            Benefit from an email containing your daily agenda, bank account balances, calculated daily
                            budget, credit score, and upcoming due payments. Alternatively, automate your stock trading
                            strategy based on specific indicators and conditions.
                        </p>
                        <div className={"section-subheading"}>$50+/month</div>
                        <p className={"section-content margin-bottom-0"}>
                            Enjoy a comprehensive end-of-day business report, including sales details, resource usage,
                            and budget balances, or implement a sophisticated trading system with multiple stocks and
                            indicators, backed by seamless database integration.

                            Experience the exceptional value of our SEO-optimized pricing structure at Rexius
                            Algorithms. We offer customized solutions tailored to your automation needs, ensuring
                            efficiency, affordability, and scalability. Get started today by reaching out to us, and let
                            us unlock the full potential of automation for your business.
                        </p>
                    </div>
                </div>
                <ContactForm/>
            </div>
            <Footer/>
        </div>
    )

}

export default Pricing;