import LazyLoad from "./LazyLoad";
import {useState} from "react";
import {forgotPassword} from "../service/ResetPasswordService";
import {sendContactEmail} from "../service/ContactFormService";
import {useNavigate} from "react-router-dom";
import Loading from "./Loading";

function ContactForm(props) {

    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
            setLoading(true);
            try {
                sendContactEmail(name, email, message)
                    .then(contactEmailResponse => {
                        if (contactEmailResponse === true) {
                            setSent(true);
                        } else {
                            window.alert("Could not send email.")
                        }
                        setLoading(false)
                        // navigate("/account");
                    })
                    .catch(error => {
                        console.error("Could not send email:", error);
                        setLoading(false);
                    });

            } catch (error) {
                console.error('Could not send email:', error);
                setLoading(false);
            }
        };


    const contactForm = (
        <form onSubmit={handleSubmit} className={"contact-form-form slide-in-right"}>
            <input className={"contact-form-input"}
                   placeholder={"Full Name"}
                   value={name}
                   onChange={(e) => setName(e.target.value)}
            />
            <input className={"contact-form-input"}
                   type={"email"}
                   placeholder={"Email Address"}
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
            />
            <textarea className={"contact-form-text-area"}
                      placeholder={"Automation Service"}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
            />
            <div className={"send-wrapper"}>
                <button type={"submit"} className="send">Send</button>
            </div>
        </form>
    )
    const sentForm = (
        <div className="content">
            <div className={"no-account"}>An email has been sent to our staff!</div>
            <br/>
            <div className={"no-account"}>If you do not receive an email within 24 hours make sure to check your spam folder.</div>
        </div>
    )
    // if (props.contact) {
        return (
            <div className={"section enclosed row"}>
                <div className={"half-section"}>
                    <div className={"slide-in-left"}>
                        <div className={"section-heading"}>Let's Connect</div>
                        <p className={"section-content"}>Let us know your preferred automation service by filling
                            out
                            this
                            form.
                            With your input, we'll tailor a solution that perfectly aligns with your needs.</p>
                    </div>
                </div>
                <div className={"half-section contact-form"}>
                    {loading ? <Loading text={"Sending Contact Email"}/>
                        :
                        !sent ?
                            contactForm
                            :
                            sentForm
                    }
                </div>
            </div>
        )
    // } else {
    //
    //     return (
    //         <div className={"section enclosed row"}>
    //             <div className={"half-section mobile-negative-margin-top-1-5"}>
    //                 <LazyLoad>
    //                     <div className={"slide-in-left"}>
    //                         <div className={"section-heading section-heading-enclosed"}>Let's Connect</div>
    //                         <p className={"section-content"}>Let us know your preferred automation service by filling
    //                             out
    //                             this
    //                             form.
    //                             With your input, we'll tailor a solution that perfectly aligns with your needs.</p>
    //                     </div>
    //                 </LazyLoad>
    //             </div>
    //             <div className={"half-section contact-form"}>
    //                 <LazyLoad>
    //                     <form onSubmit={handleSubmit} className={"contact-form-form slide-in-right"}>
    //                         <input className={"contact-form-input"}
    //                                placeholder={"Full Name"}
    //                                value={email.name}
    //                                onChange={(e) => setName(e.target.value)}
    //                         />
    //                         <input className={"contact-form-input"}
    //                                placeholder={"Email Address"}
    //                                value={email.emailAddress}
    //                                onChange={(e) => setEmail(e.target.value)}
    //                         />
    //                         <textarea className={"contact-form-text-area"}
    //                                   placeholder={"Automation Service"}
    //                                   value={email.message}
    //                                   onChange={(e) => setMessage(e.target.value)}
    //                         />
    //                         <div className={"send-wrapper"}>
    //                             <button type={"submit"} className="send">Send</button>
    //                         </div>
    //                     </form>
    //                 </LazyLoad>
    //             </div>
    //         </div>
    //     )
    // }
}

export default ContactForm;