import Header from "./Header";
import Footer from "./Footer";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {preRegister, register} from "../service/AuthService";
import Loading from "./Loading";

function SignUp() {

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmationToken, setConfirmationToken] = useState('');
    const [form, setForm] = useState("register");

    const [loading, setLoading] = useState(false);

    function validatePassword() {
        // Regex explanation:
        // ^                 : start of string
        // (?=.*[a-z])       : at least one lowercase letter
        // (?=.*[A-Z])       : at least one uppercase letter
        // (?=.*\d)          : at least one number
        // .{8,}             : at least 8 characters
        // $                 : end of string
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

        return regex.test(password);
    }

    const handlePreRegister = async (event) => {
        event.preventDefault();
        if (!validatePassword()) {
            window.alert('Password must be at least 8 characters long and must include at least 1 uppercase letter, 1 lowercase letter, and 1 number.')
        } else if (password !== confirmPassword) {
            window.alert('Passwords do not match.')
        } else {
            setLoading(true);
            try {
                preRegister(email)
                    .then(preRegisterResponse => {
                        if (preRegisterResponse.confirmation === "exists") {
                            window.alert('This email already exists. Please log in.');
                        } else if (preRegisterResponse.confirmation === 'fail') {
                            window.alert('Error Registering Account')
                        } else if (preRegisterResponse.confirmation === 'success') {
                            setForm('confirm');
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Error registering:", error);
                        window.alert('Error Registering Account')
                        setLoading(false);
                    });
            } catch (error) {
                console.error('Login failed:', error);
                window.alert('Error Registering Account')

                setLoading(false);
            }
        }

    };

    const handleConfirm = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            register(firstName, lastName, email, password, confirmationToken)
                .then(registerResponse => {
                    localStorage.setItem('raAccessToken', JSON.stringify(registerResponse.access_token));
                    localStorage.setItem('raRefreshToken', JSON.stringify(registerResponse.refresh_token));
                    navigate("/account");
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error registering:", error);
                    window.alert('Error Registering Account')
                    setLoading(false);
                });

        } catch (error) {
            console.error('Login failed:', error);
            setLoading(false);
        }
    };

    const confirmationForm = (
        <div className="content">
            <form className={"login-form"} onSubmit={handleConfirm}>
                <div className="login-form-component">
                    <div className={"login-form-label"}>A confirmation code has been sent to {email}.</div>
                    <label className={"login-form-label"} htmlFor="confirmationToken">If you cannot find it, be sure to
                        check your spam folder.</label>
                    <input
                        className={"contact-form-input"}
                        style={{marginTop: '10px'}}
                        type="text"
                        id="confirmationToken"
                        placeholder={"Confirmation Code"}
                        value={confirmationToken}
                        onChange={(e) => setConfirmationToken(e.target.value)}
                        required
                    />
                </div>
                <button className={"send"} type="submit">Confirm</button>
            </form>
        </div>
    )

    const registerForm = (
        <form onSubmit={handlePreRegister} className={"slide-in-right"}>
            <input className={"contact-form-input"}
                   required
                   placeholder={"First Name"}
                   value={firstName}
                   onChange={(e) => setFirstName(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   placeholder={"Last Name"}
                   value={lastName}
                   onChange={(e) => setLastName(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"email"}
                   placeholder={"Email"}
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"Password"}
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"Confirm Password"}
                   value={confirmPassword}
                   onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className={"send-wrapper mobile-margin-bottom-0"}>
                <button type={"submit"} className="send">Sign Up</button>
            </div>
        </form>
    );

    return (
        <div className={"signup page"}>
            <Header/>
            <div className={"page-heading"}>SIGN UP</div>
            <div className={"content"}>
                <div className={"section enclosed row"}>
                    <div className={"half-section login-form"}>
                        <div className={"section-heading"}>Sign Up</div>
                        {loading ?
                            (
                                <Loading text={"Signing Up"}/>
                            ) : (
                                form === 'register' ? registerForm : confirmationForm
                            )}
                        <div className={"have-account"}>
                            {form === 'register' ? (
                                <div>
                                    <span>Already have an account? Click </span>
                                    <a href={"/login"} className={"click-here"}>here</a>
                                    <span> to log in!</span>
                                </div>
                            ) : (
                                <div>
                                    <span className={"click-here"} onClick={() => setForm("register")}>Back to Registration Form</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default SignUp;