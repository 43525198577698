import Header from "./Header";
import Footer from "./Footer";
import {useState} from "react";
import Loading from "./Loading";
import {authenticate, getJwtRole} from "../service/AuthService";
import {useNavigate} from "react-router-dom";

function LogIn() {

    const navigate = useNavigate();

    let [role, setRole] = useState(getJwtRole());
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            authenticate(email, password)
                .then(loginResponse => {
                    if (loginResponse === 500) {
                        window.alert("Login Failed");
                        setLoading(false);
                    } else {
                        localStorage.setItem('raAccessToken', JSON.stringify(loginResponse.access_token));
                        localStorage.setItem('raRefreshToken', JSON.stringify(loginResponse.refresh_token));
                        role = getJwtRole();
                        setRole(role);
                        role === 'ROLE_ADMIN' ? navigate("/admin") : navigate("/account");
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error("Error logging in:", error);
                    setLoading(false);
                });

        } catch (error) {
            console.error('Login failed:', error);
            setLoading(false);
        }
    };

    const loginForm = (
        <form onSubmit={handleSubmit} className={"slide-in-right"}>
            <input className={"contact-form-input"}
                   required
                   type={"email"}
                   placeholder={"Email"}
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"Password"}
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
            />
            <div className={"send-wrapper mobile-margin-bottom-0"}>
                <button type={"submit"} className="send">Log In</button>
            </div>
        </form>
    );

    return (
        <div className={"login page"}>
            <Header/>
            <div className={"page-heading"}>LOG IN</div>
            <div className={"content"}>
                <div className={"section enclosed row"}>
                    <div className={"half-section login-form"}>
                        <div className={"section-heading"}>Log In</div>
                        {loading ? <Loading text={"Logging In"}/> :
                            <>
                                {loginForm}
                                <div className={"have-account"}>
                                    <span>New to Rexius Algorithms? Click </span>
                                    <a href={"/signup"} className={"click-here"}>here</a>
                                    <span> to sign up!</span>
                                </div>
                                <div className={"have-account"}>
                                    <a href={"/forgot-password"} className={"click-here"}>Forgot your password?</a>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default LogIn;