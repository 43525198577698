import Header from "./Header";
import Footer from "./Footer";

function Terms() {
    return (
        <div className={"services page"}>
            <Header/>
            <div className={"small-heading"}>TERMS & CONDITIONS</div>
            <div className={"content"}>
                <div className={"services-page-text margin-bottom-2"}>
                    <div className={"section open"}>
                        <div className={"section-subheading left margin-bottom-half-rem"}>1. Introduction</div>
                        <p className={"section-content"}>
                            These Legal Terms and Conditions (“Terms”) govern your use of the services provided by
                            Rexius Algorithms LLC (“Rexius Algorithms,” “We,” “Us,” or “Our”), a limited liability
                            company duly organized and existing under the laws of the State of California, USA, with its
                            principal place of business located in the city of Torrance. By accessing and utilizing the
                            tailored automation solutions and services offered by Rexius Algorithms LLC, you acknowledge
                            that you have read, understood, and agreed to be bound by these Terms. If you do not agree
                            with any part of these Terms, you are not permitted to use our services.
                        </p>
                        <div
                            className={"section-subheading left margin-bottom-half-rem margin-bottom-half-rem"}>2.
                            Scope of Services
                        </div>
                        <p className={"section-content"}>
                            Rexius Algorithms LLC specializes in providing tailored automation solutions designed to
                            cater to your specific requirements. Our team of skilled engineers and automation experts
                            possesses extensive industry experience, enabling us to design and implement highly
                            efficient automated systems across diverse sectors. We are committed to empowering our
                            esteemed clients by automating repetitive tasks, streamlining workflows, and maximizing
                            productivity through our comprehensive suite of automation services.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>3. Intellectual Property
                        </div>
                        <p className={"section-content"}>
                            All content, materials, and intellectual property made available by Rexius Algorithms LLC,
                            including but not limited to software, algorithms, designs, and documentation, remain the
                            exclusive property of Rexius Algorithms LLC or its licensors. You are strictly prohibited
                            from reproducing, distributing, modifying, displaying, performing, or creating derivative
                            works based on our intellectual property without our prior express written consent.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>4. Limitation of Liability</div>
                        <p className={"section-content"}>
                            While Rexius Algorithms LLC endeavors to provide top-tier automation solutions, we shall not
                            be liable for any direct, indirect, incidental, consequential, or exemplary damages arising
                            from or related to your use of our services. Such damages may include, but are not limited
                            to, loss of profits, data, business opportunities, or goodwill. You acknowledge that our
                            services are provided “as-is” and without warranties of any kind, express or implied.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>5. Privacy Policy</div>
                        <p className={"section-content"}>
                            Rexius Algorithms LLC values the privacy of our clients and takes the protection of personal
                            information seriously. Our Privacy Policy outlines how we collect, process, use, and
                            safeguard your personal information. By using our services, you consent to the practices
                            described in our Privacy Policy.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>6. Third-Party Links</div>
                        <p className={"section-content"}>
                            Our services may contain links to third-party websites or resources that are not owned or
                            controlled by Rexius Algorithms LLC. We do not endorse, warrant, or assume any
                            responsibility for the content, privacy policies, or practices of these third-party websites
                            or resources. Your interactions with such third parties are at your own risk.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>7. Payment and Data Saving</div>
                        <div className={"section-content"}>
                            <div className={"margin-bottom-half-rem"}>
                                <span className={"bold"}>7.1</span> Automated Monthly Payments: Rexius Algorithms LLC
                                may collect and securely store your payment information, including credit card details,
                                for the purpose of processing automated monthly payments for the services you subscribe
                                to. By providing your payment information, you authorize us to charge your credit card
                                for the agreed-upon amount on a monthly basis.
                            </div>
                            <div>
                                <span className={"bold"}>7.2</span> Data Saving: We may retain your personal information
                                for the duration necessary to fulfill the purposes outlined in this Agreement, unless a
                                longer retention period is required or permitted by relevant legal obligations. Your
                                data will be stored and processed in accordance with our Privacy Policy.
                            </div>
                        </div>
                        <div className={"section-subheading left margin-bottom-half-rem"}>8. Indemnification</div>
                        <p className={"section-content"}>
                            You agree to indemnify, defend, and hold harmless Rexius Algorithms LLC, its officers,
                            directors, employees, agents, and affiliates from and against any and all claims, demands,
                            losses, damages, liabilities, costs, and expenses, including reasonable attorney’s fees,
                            arising out of or related to your use of our services or any violation of these Terms.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>9. Governing Law and
                            Jurisdiction
                        </div>
                        <p className={"section-content"}>
                            These Terms shall be governed by and construed in accordance with the laws of the State of
                            California, USA, and any dispute arising out of or relating to these Terms shall be
                            exclusively submitted to the state and federal courts located in Torrance, California, USA.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>10. Modifications to the Terms
                        </div>
                        <p className={"section-content"}>
                            Rexius Algorithms LLC reserves the right to modify, amend, or update these Terms as needed
                            and in accordance with legal requirements. Any changes will be posted on our website, and
                            your continued use of our services after such modifications signifies your acceptance of the
                            updated Terms.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>11. Entire Agreement
                        </div>
                        <p className={"section-content"}>
                            These Terms constitute the entire agreement between you and Rexius Algorithms LLC regarding
                            the subject matter herein and supersede all prior or contemporaneous understandings and
                            agreements, whether written or oral.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>12. Contact Information</div>
                        <div className={"section-content"}>
                            <div className={"margin-bottom-half-rem bold"}>
                                For any inquiries or questions concerning these Terms or our services, you may contact
                                us at:
                            </div>
                            <div className={"margin-bottom-half-rem"}>
                                <span className={"bold"}>Email:</span> legal@rexiusalgorithms.com
                            </div>
                            <p className={"section-content"}>
                                By using the tailored automation solutions and services offered by Rexius Algorithms
                                LLC, you expressly acknowledge that you have carefully read, understood, and consented
                                to be bound by these Legal Terms and Conditions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Terms;