import Header from "./Header";
import Footer from "./Footer";

function Privacy() {
    return (
        <div className={"services page"}>
            <Header/>
            <div className={"small-heading"}>PRIVACY POLICY</div>
            <div className={"content"}>
                <div className={"services-page-text margin-bottom-2"}>
                    <div className={"section open"}>
                        <div className={"section-subheading left margin-bottom-half-rem"}>1. Introduction</div>
                        <p className={"section-content"}>
                            This Privacy Policy (“Policy”) constitutes a legally binding agreement between you (“User”
                            or “Users”) and Rexius Algorithms LLC (“Rexius Algorithms,” “We,” “Us,” or “Our”), a limited
                            liability company based in the State of California, United States of America (“USA”),
                            regarding the collection, processing, usage, storage, and protection of your personal
                            information while accessing and utilizing the services provided through the Rexius
                            Algorithms website or mobile application (the “Site”). The purpose of this Policy is to
                            inform Users about the types of information we may collect, how we handle and safeguard such
                            information, and the choices Users have concerning their personal data.
                        </p>
                        <div
                            className={"section-subheading left margin-bottom-half-rem margin-bottom-half-rem"}>2. Information
                            We Collect
                        </div>
                        <div className={"section-content"}>
                            <div className={"margin-bottom-half-rem"}>
                                <span className={"bold"}>2.1</span> Personal Information: Rexius Algorithms LLC, in
                                pursuit
                                of delivering exceptional
                                tailored automation solutions and services, may collect personal identification
                                information
                                from Users. This information is voluntarily provided through Users’ interactions with
                                the
                                Site and may encompass names, email addresses, mailing addresses, phone numbers, and
                                other
                                personally identifiable data.
                            </div>
                            <div>
                                <span className={"bold"}>2.2</span> Automatically Collected Information: To optimize
                                User
                                experience and conduct statistical
                                analysis, non-personal identification information may be automatically collected when
                                Users
                                access the Site. Such data includes, but is not limited to, IP addresses, browser types,
                                operating systems, device information, and other technical particulars.
                            </div>
                        </div>
                        <div className={"section-subheading left margin-bottom-half-rem"}>3. Purpose of Data Processing
                        </div>
                        <div className={"section-content"}>
                            <div className={"margin-bottom-half-rem"}>
                                <span className={"bold"}>We process the collected information for the following legitimate purposes:</span>
                            </div>
                            <div className={"margin-bottom-half-rem"}>
                                <span className={"bold"}>3.1</span> Tailored Services: Personal information enables
                                Rexius
                                Algorithms LLC to offer highly efficient automated systems and consulting services,
                                meticulously customized to meet the specific requirements of each User.
                            </div>
                            <div className={"margin-bottom-half-rem"}>
                                <span className={"bold"}>3.2</span> Enhanced User Experience: Automatically collected
                                information is utilized to analyze trends, administer the Site, and enhance User
                                experience
                                and functionality.
                            </div>
                            <div className={"margin-bottom-half-rem"}>
                                <span className={"bold"}>3.3</span> Communication: Contact information provided by Users
                                facilitates effective communication regarding service inquiries, responses to queries,
                                and
                                other relevant matters.
                            </div>
                            <div className={"margin-bottom-half-rem"}>
                                <span className={"bold"}>3.4</span> Automated Monthly Payments: Rexius Algorithms LLC
                                may
                                collect and securely store your payment information, including credit card details, for
                                the
                                purpose of processing automated monthly payments for the services you subscribe to. By
                                providing your payment information, you authorize us to charge your credit card for the
                                agreed-upon amount on a monthly basis.
                            </div>
                            <div>
                                <span className={"bold"}>3.5</span> Subscription Information: Rexius Algorithms LLC will
                                collect the email addresses and names of users who subscribe to our services to
                                facilitate
                                service delivery and communication.
                            </div>
                        </div>
                        <div className={"section-subheading left margin-bottom-half-rem"}>4. Data Security</div>
                        <p className={"section-content"}>
                            Recognizing the paramount importance of data security, Rexius Algorithms LLC implements
                            appropriate organizational, technical, and administrative measures to safeguard personal
                            information from unauthorized access, disclosure, alteration, or destruction. Robust
                            encryption protocols, secure socket layer (SSL) technology, and stringent access controls
                            are employed to ensure the confidentiality and integrity of data.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>5. Data Retention</div>
                        <p className={"section-content"}>
                            Personal information is retained for the duration necessary to fulfill the purposes outlined
                            in this Policy, unless a longer retention period is required or permitted by relevant legal
                            obligations.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>6. Sharing of Information</div>
                        <p className={"section-content"}>
                            Rexius Algorithms LLC does not sell, rent, or share personal information with third parties.
                            All data collected is processed and managed solely by Rexius Algorithms LLC.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>7. User Rights</div>
                        <p className={"section-content"}>
                            Users retain certain rights concerning their personal information held by Rexius Algorithms
                            LLC. These rights encompass access, correction, and deletion of data. For any data-related
                            requests or concerns, Users may communicate with us through the contact information provided
                            below.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>8. Changes to this Policy</div>
                        <p className={"section-content"}>
                            Rexius Algorithms LLC reserves the unequivocal right to modify, amend, or update this Policy
                            as necessary and in accordance with legal requirements. Any changes will be duly reflected
                            on the Site, and Users will be notified if mandated by applicable laws.
                        </p>
                        <div className={"section-subheading left margin-bottom-half-rem"}>9. Contact Information</div>
                        <div className={"section-content"}>
                            <div className={"margin-bottom-half-rem"}>

                                For any inquiries or questions pertaining to this Policy or our data practices, Users
                                may direct their communications to our esteemed legal department through the following
                                contact details:
                            </div>
                            <div>
                                <span className={"bold"}>Email:</span> legal@rexiusalgorithms.com
                            </div>
                        </div>
                        <div className={"section-subheading left margin-bottom-half-rem"}>10. Governing Law and
                            Jurisdiction
                        </div>
                        <p className={"section-content"}>
                            This Policy shall be governed by and interpreted in strict accordance with the laws of the
                            State of California, USA, without regard to its conflicts of law principles. Any dispute
                            arising out of or relating to this Policy shall be exclusively subject to the competent
                            jurisdiction of the courts located in the State of California, USA.
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Privacy;