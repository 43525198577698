import Header from "../Header";
import Footer from "../Footer";
import workflow from "../../images/workflow-automation.png";

function WorkflowAutomation() {

    return (
        <div className={"sub-service page"}>
            <Header/>
            <div className={"small-heading"}>WORKFLOW AUTOMATION</div>
            <div className={"content"}>
                <div className={"sub-service-page-text"}>
                    <img className={"service-section-service-image scale-up margin-bottom-2"} src={workflow}
                         alt={"task-automation"}/>
                    <div className={"section open slide-in-top"}>
                        <div className={"section-subheading"}>Streamline Your Operations with Workflow Automation
                            Solutions
                        </div>
                        <p className={"section-content"}>
                            We understand that efficiency is vital, especially for small businesses. We offer
                            specialized workflow automation solutions to optimize your operations and maximize
                            productivity. Our advanced technology is tailored to meet the unique needs of small
                            businesses, ensuring that every process flows seamlessly and efficiently.
                        </p>
                        <div className={"section-subheading"}>What Do Workflow Automation Solutions Include?</div>
                        <p className={"section-content"}>
                            Workflow automation solutions involve implementing automated systems that align with your
                            business processes. This means tasks, notifications, and processes are executed precisely
                            and consistently, freeing your valuable time and resources for more strategic endeavors.
                        </p>
                        <div className={"section-subheading"}>Why Should You Choose Workflow Automation</div>
                        <div className={"section-content"}>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Enhanced Efficiency</span> – Routine tasks are handled
                                automatically with workflow automation, allowing you to focus on critical aspects of
                                your business. This leads to increased overall productivity and growth.
                            </div>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Cost-Effective Solutions</span> – Our workflow automation
                                solutions are designed especially for small businesses. We understand the importance of
                                cost-effectiveness, and our solutions are structured to provide excellent returns on
                                investment.
                            </div>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Consistent Process Execution</span> – Automation ensures that
                                every task is carried out in the same manner every time. This leads to higher quality
                                outcomes and improved customer satisfaction.
                            </div>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Adaptability to Growth</span> – As your business grows, your
                                operations may become more complex. Workflow automation solutions can be scaled to
                                accommodate increased demands, ensuring your processes remain efficient and effective.
                            </div>
                            To boost your small business’s efficiency, partner with Rexius Algorithms and discuss
                            workflow automation for your small business’s unique requirements with our experts!
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default WorkflowAutomation;