import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchDashboardUser} from "../service/UserService";
import {unixTimestampToDate} from "../service/DateService";
import Header from "./Header";
import Loading from "./Loading";
import Footer from "./Footer";
import PaymentMethodForm from "./stripe/PaymentMethodForm";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {stripePublishableKey} from "../constants/Constants";

const border = 'solid 2px #b619ff';
const none = 'none'

function UserDashboard() {

    // const year = new Date().getFullYear();
    // const month = new Date().getMonth();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const path = window.location.pathname.split("/");
    const userId = path[path.length - 1];
    const [userError, setUserError] = useState(false);

    const [userInfo, setUserInfo] = useState({
        "id": 6,
        "firstName": "Brett",
        "lastName": "Rexius",
        "email": "brettrexius@gmail.com",
        "dateCreated": 1712293137751,
        "stripeId": "cus_ProUPPtpwXvTWX",
        "stripeInvoicePrefix": "C759B3A1",
        "paymentMethod": {
            "id": "pm_1PBldeDWKCrBGE2tZaRSUBJM",
            "expMonth": 5,
            "expYear": 2028,
            "lastFour": 4242,
            "network": "visa",
            "funding": "credit"
        },
        "subscriptions": [
            {
                "id": "sub_1PBlglDWKCrBGE2t3nl4T2Pn",
                "created": 1714602707,
                "email": "brettrexius@gmail.com",
                "firstName": "Brett",
                "lastName": "Rexius",
                "stripeInvoicePrefix": "C759B3A1",
                "name": "test5",
                "description": "test desc 5",
                "customerId": "cus_ProUPPtpwXvTWX",
                "amount": 10.0,
                "startDate": 1714602707,
                "active": true
            }
        ],
        "paymentLinks": [
            {
                "id": "plink_1PCr85DWKCrBGE2tJficFBTO",
                "name": "test payment link 3",
                "active": true,
                "url": "https://buy.stripe.com/test_28o8ys50w5VY4ikeUW",
                "lineItemId": "li_Q2x2h6oMd8zRmj",
                "amount": 25.0,
                "description": "test payment 3 description",
                "created": 1714861949,
                "customerId": "6",
                "customerStripeId": "cus_ProUPPtpwXvTWX",
                "firstName": "Brett",
                "lastName": "Rexius",
                "email": "brettrexius@gmail.com",
                "stripeInvoicePrefix": "C759B3A1"
            },
            {
                "id": "plink_1PCBlaDWKCrBGE2tsCQaJ9PU",
                "name": "test payment link 2",
                "active": true,
                "url": "https://buy.stripe.com/test_6oEeWQ0Kg3NQ7uw3cd",
                "lineItemId": "li_Q2GIOohLhYJLZ9",
                "amount": 10.0,
                "description": "test payment link 2 description",
                "created": 1714702950,
                "customerId": "6",
                "customerStripeId": "cus_ProUPPtpwXvTWX",
                "firstName": "Brett",
                "lastName": "Rexius",
                "email": "brettrexius@gmail.com",
                "stripeInvoicePrefix": "C759B3A1"
            }
        ],
        "charges": [
            {
                "id": "ch_3PCr9qDWKCrBGE2t1Qr2f3ey",
                "email": "brettrexius@gmail.com",
                "firstName": "Brett",
                "lastName": "Rexius",
                "stripeId": "cus_ProUPPtpwXvTWX",
                "amount": 25.0,
                "date": 1714862058
            },
            {
                "id": "ch_3PCCloDWKCrBGE2t3gynPYq2",
                "email": "brettrexius@gmail.com",
                "firstName": "Brett",
                "lastName": "Rexius",
                "stripeId": "cus_ProUPPtpwXvTWX",
                "amount": 10.0,
                "date": 1714706808
            },
            {
                "id": "ch_3PBlglDWKCrBGE2t132bMI0Q",
                "email": "brettrexius@gmail.com",
                "firstName": "Brett",
                "lastName": "Rexius",
                "stripeId": "cus_ProUPPtpwXvTWX",
                "amount": 10.3,
                "date": 1714602708
            }
        ]
    });

    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem("raAccessToken")) {
            navigate("/login");
        }
        try {
            setLoading(true)
            fetchDashboardUser(true)
                .then(userInfoResponse => {
                    setUserInfo(userInfoResponse);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading user content:", error);
                    setUserError(true);
                    setLoading(false);
                });

        } catch (error) {
            console.error("Error loading user content:", error);
            setUserError(true);
            setLoading(false);
        }
    }, [toggle]);

    useEffect(() => {
        setUserDashboardContent(userContent); // This sets the initial content

    }, [userInfo]); // Re-run effect when adminDashboardInfo changes

    const paymentLinksContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Payment Links</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-18-percent"}>ID</th>
                    <th className={"th-width-18-percent"}>Name</th>
                    <th className={"th-width-18-percent"}>Description</th>
                    <th className={"th-width-18-percent"}>Date</th>
                    <th className={"th-width-18-percent"}>Amount</th>
                    <th className={"th-width-18-percent"}>Status</th>
                </tr>
                </thead>
                <tbody>
                {userInfo.paymentLinks.map((paymentLink, index) => (
                    <tr key={index} className={"dashboard-tr"}>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>{paymentLink.id}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>{paymentLink.name}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>{paymentLink.description}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>{unixTimestampToDate(paymentLink.created)}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>{paymentLink.paid ? 'Paid' : 'Active'}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/payment-link/" + paymentLink.id}
                                                                     className={"dashboard-link"}>${paymentLink.amount}</a>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );

    const chargesContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Payments</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-40-percent"}>ID</th>
                    <th className={"th-width-40-percent"}>Date</th>
                    <th className={"th-width-40-percent"}>Amount</th>
                </tr>
                </thead>
                <tbody>
                {userInfo.charges.map((charge, index) => (
                    <tr key={index} className={"dashboard-tr"}>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/charge/" + charge.id}
                                                                     className={"dashboard-link"}>{charge.id}</a></div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/charge/" + charge.id}
                                                                     className={"dashboard-link"}>
                                {unixTimestampToDate(charge.date)}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/charge/" + charge.id}
                                                                     className={"dashboard-link"}>${charge.amount}</a>

                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );

    const userContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>{userInfo.firstName} {userInfo.lastName}</div>

            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-10-percent"}>ID</th>
                    <th className={"th-width-40-percent"}>Email</th>
                    <th className={"th-width-25-percent"}>Created</th>
                    {/*<th className={"th-width-25-percent"}>Stripe ID</th>*/}
                </tr>
                </thead>
                <tbody>
                <tr className={"dashboard-tr"}>
                    <td className="dashboard-td">
                        <div className={"dashboard-td-inner"}>{userInfo.id}</div>
                    </td>
                    <td className={"dashboard-td"}>
                        <div className={"dashboard-td-inner"}>{userInfo.email}</div>
                    </td>
                    <td className={"dashboard-td"}>
                        <div className={"dashboard-td-inner"}>{unixTimestampToDate(userInfo.dateCreated / 1000)}
                        </div>
                    </td>
                    {/*<td className={"dashboard-td"}>*/}
                    {/*    <div className={"dashboard-td-inner"}><a href={"admin/user/" + userInfo.id}*/}
                    {/*                                             className={"dashboard-link"}>{userInfo.stripeId}</a>*/}
                    {/*    </div>*/}
                    {/*</td>*/}
                </tr>
                </tbody>
            </table>
        </div>
    );

    const subscriptionsContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Subscriptions</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-18-percent"}>ID</th>
                    <th className={"th-width-18-percent"}>Name</th>
                    <th className={"th-width-18-percent"}>Description</th>
                    <th className={"th-width-18-percent"}>Date</th>
                    <th className={"th-width-18-percent"}>Status</th>
                    <th className={"th-width-18-percent"}>Amount</th>
                </tr>
                </thead>
                <tbody>
                {userInfo.subscriptions.map((subscription, index) => (
                    <tr key={index} className={"dashboard-tr"}>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>{subscription.id}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>{subscription.name}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>{subscription.description}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>{unixTimestampToDate(subscription.startDate)}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>{subscription.active ? 'Active' : 'Inactive'}</a>
                            </div>
                        </td>
                        <td className={"dashboard-td"}>
                            <div className={"dashboard-td-inner"}><a href={"/dashboard/subscription/" + subscription.id}
                                                                     className={"dashboard-link"}>${subscription.amount}</a>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

    );

    const [editingPayment, setEditingPayment] = useState(false);

    const blockInvalidChar = (e) => ['e', 'E', '+', '-', checkNeg(e.target.value)].includes(e.key) && e.preventDefault();

    function checkNeg(val) {
        return val.length > 0 ? '-' : '';
    }

    // function handleMonthInput(event) {
    //     const value = event.target.value;
    //     // Ensure the value is within the range and has a maximum of 2 digits
    //     if (value.length > 2) {
    //         event.target.value = value.slice(0, 2);
    //     }
    // }
    //
    // function handleCvvInput(event) {
    //     const value = event.target.value;
    //     // Ensure the value is within the range and has a maximum of 2 digits
    //     if (value.length > 3) {
    //         event.target.value = value.slice(0, 3);
    //     }
    // }
    //
    // function handleYearInput(event) {
    //     const value = event.target.value;
    //     if (value === 'e') {
    //         event.target.value = value.slice(0, value.length - 1);
    //     }
    //     // Ensure the value is within the range and has a maximum of 4 digits
    //     else if (value.length > 4) {
    //         event.target.value = value.slice(0, 4);
    //     }
    // }

    const [savingCardInfo, setSavingCardInfo] = useState(false);

    function handlePaymentButton(command) {
        if (command === 'edit') {
            setUserDashboardContent(paymentMethodContentEdit)
        } else if (command === 'cancel') {
            setUserDashboardContent(paymentMethodContent)
        }
    }

    const paymentMethodContent = (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Payment Method</div>
            <table className={"dashboard-table"}>
                <thead>
                <tr className={"dashboard-tr"}>
                    <th className={"th-width-50-percent"}>Number</th>
                    <th className={"th-width-30-percent"}>Expiration</th>
                </tr>
                </thead>
                <tbody>
                <tr className={"dashboard-tr"}>
                    <td className={"dashboard-td"}>{userInfo.paymentMethod?.lastFour}</td>
                    <td className={"dashboard-td"}>{userInfo.paymentMethod?.expMonth}/{userInfo.paymentMethod?.expYear}</td>
                </tr>
                </tbody>
            </table>
            <div className={"save-wrapper"}>
                <button className="save" onClick={() => handlePaymentButton('edit')}>Edit</button>
            </div>
        </div>
    );

    // function sendPaymentMethod(number, expMonth, expYear, cvv) {
    //     try {
    //         setSavingCardInfo(true);
    //         editPaymentMethod(true, number, expMonth, expYear, cvv)
    //             .then(paymentResponse => {
    //                 if (paymentResponse === true) {
    //                     window.alert('Payment method updated!');
    //                     setSavingCardInfo(false);
    //                     setToggle(!toggle);
    //                 } else {
    //                     window.alert('Could not update payment method.');
    //                     setSavingCardInfo(false);
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error("Error loading payment method:", error);
    //                 window.alert('Could not update payment method.');
    //                 setSavingCardInfo(false);
    //             });
    //
    //     } catch (error) {
    //         console.error("Error loading payment method:", error);
    //         window.alert('Could not update payment method.');
    //         setSavingCardInfo(false);
    //     }
    // }

    // const elements = useElements();
    // const stripe = useStripe();
    const stripePromise = loadStripe(stripePublishableKey);


    // const handleSendPaymentMethod = async (e) => {
    //     e.preventDefault();
    //     const number = document.getElementById("edit-payment-method-number").value;
    //     let expMonth = document.getElementById("edit-payment-method-exp-month").value;
    //     expMonth = parseInt(expMonth, 10);
    //     const expYear = document.getElementById("edit-payment-method-exp-year").value;
    //     const cvv = document.getElementById("edit-payment-method-cvv").value;
    //
    //     if (number === null || number.length !== 16) {
    //         window.alert('Card Number Invalid');
    //     } else if (expMonth === null || expMonth.length < 1 || expMonth.length > 2 || expMonth < 1 || expMonth > 12) {
    //         console.log(expMonth.length)
    //         window.alert('Expiration Month Invalid');
    //     } else if (expYear === null || expYear.length !== 4 || expYear < year) {
    //         window.alert('Expiration Year Invalid');
    //     } else if (cvv === null || cvv.length !== 3) {
    //         window.alert('CVV Invalid');
    //     } else if (isDateInvalid(expMonth, expYear))
    //         window.alert('Card Is Expired');
    //     else {
    //         if (!stripe) {
    //             // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
    //             return;
    //         }
    //         setSavingCardInfo(true);
    //         const {error, paymentMethod} = await stripe.createPaymentMethod({
    //             type: 'card',
    //             card: {
    //                 number: number,
    //                 exp_month: parseInt(expMonth),
    //                 exp_year: parseInt(expYear),
    //                 cvc: cvv,
    //             },
    //         });
    //
    //         if (error) {
    //             console.error(error);
    //             window.alert('Could not update payment method.');
    //             setSavingCardInfo(false);
    //         } else {
    //             try {
    //                 editPaymentMethod(true, paymentMethod.id)
    //                     .then(paymentResponse => {
    //                         if (paymentResponse === true) {
    //                             window.alert('Payment method updated!');
    //                             setSavingCardInfo(false);
    //                             setToggle(!toggle);
    //                         } else {
    //                             window.alert('Could not update payment method.');
    //                             setSavingCardInfo(false);
    //                         }
    //                     })
    //                     .catch(error => {
    //                         console.error("Error loading payment method:", error);
    //                         window.alert('Could not update payment method.');
    //                         setSavingCardInfo(false);
    //                     });
    //
    //             } catch (error) {
    //                 console.error("Error loading payment method:", error);
    //                 window.alert('Could not update payment method.');
    //                 setSavingCardInfo(false);
    //             }
    //
    //         }
    //
    //         // sendPaymentMethod(number, expMonth, expYear, cvv);
    //     }
    //
    // }


    // function isDateInvalid(expMonth, expYear) {
    //     if (expYear === year) {
    //         if (expMonth <= month) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    // const paymentMethodContentEdit = (
    //     <Elements stripe={stripePromise}>
    //         <PaymentMethodForm />
    //     </Elements>
    // );

    const [userDashboardContent, setUserDashboardContent] = useState(userContent)


    const paymentMethodContentEdit = (
        <Elements stripe={stripePromise}>

            <PaymentMethodForm setSavingCardInfo={setSavingCardInfo} setToggle={setToggle} toggle={toggle}
                               handlePaymentButton={handlePaymentButton}
                               handleDashboardEntryClick={handleDashboardEntryClick}
                               setUserDashboardContent={setUserDashboardContent}
                               paymentMethodContent={paymentMethodContent}/>

            {/*<div className={"admin-dashboard-content"}>*/}
            {/*    <div className={"section-subheading admin-subheading"}>Payment Method</div>*/}
            {/*    <form onSubmit={handleSendPaymentMethod}>*/}
            {/*        <table className={"dashboard-table"}>*/}
            {/*            <thead>*/}
            {/*            <tr className={"dashboard-tr"}>*/}
            {/*                <th className={"th-width-50-percent"}>Number</th>*/}
            {/*                <th className={"th-width-30-percent"}>Expiration</th>*/}
            {/*                <th className={"th-width-25-percent"}>CVV</th>*/}
            {/*            </tr>*/}
            {/*            </thead>*/}
            {/*            <tbody>*/}
            {/*            <tr className={"dashboard-tr"}>*/}
            {/*                <td className={"dashboard-td"}><input className={"edit-payment-input"}*/}
            {/*                                                      id={"edit-payment-method-number"}*/}
            {/*                                                      type={"number"}*/}
            {/*                                                      placeholder={"16-digit card number"}/></td>*/}
            {/*                <td className={"dashboard-td"}><input className={"edit-payment-input"}*/}
            {/*                                                      id={"edit-payment-method-exp-month"}*/}
            {/*                                                      type={"number"}*/}
            {/*                                                      placeholder={"MM"}*/}
            {/*                                                      min={1}*/}
            {/*                                                      max={12}*/}
            {/*                                                      onInput={handleMonthInput}*/}
            {/*                                                      onKeyDown={blockInvalidChar}/>/*/}
            {/*                    <input className={"edit-payment-input"}*/}
            {/*                           id={"edit-payment-method-exp-year"}*/}
            {/*                           type={"number"}*/}
            {/*                           placeholder={"YYYY"}*/}
            {/*                           min={year}*/}
            {/*                           onInput={handleYearInput}*/}
            {/*                           onKeyDown={blockInvalidChar}/></td>*/}
            {/*                <td className={"dashboard-td"}><input className={"edit-payment-input"}*/}
            {/*                                                      id={"edit-payment-method-cvv"}*/}
            {/*                                                      type={"number"}*/}
            {/*                                                      placeholder={"***"}*/}
            {/*                                                      onInput={handleCvvInput}*/}
            {/*                                                      onKeyDown={blockInvalidChar}/></td>*/}
            {/*            </tr>*/}
            {/*            </tbody>*/}
            {/*        </table>*/}
            {/*        <div className={"save-wrapper"}>*/}
            {/*            <button type={"submit"} className="save">Save</button>*/}
            {/*            <button className="save" onClick={() => handlePaymentButton('cancel')}>Cancel</button>*/}
            {/*        </div>*/}
            {/*    </form>*/}

            {/*</div>*/}
        </Elements>
    );


    const [dashboardBorder, setDashboardBorder] = useState(border);
    const [subscriptionsBorder, setSubscriptionsBorder] = useState(none);
    const [paymentLinksBorder, setPaymentLinksBorder] = useState(none);
    const [allPaymentsBorder, setAllPaymentsBorder] = useState(none);
    const [paymentMethodBorder, setPaymentMethodBorder] = useState(none);

    function handleDashboardEntryClick(entry) {
        if (entry === 'dashboard') {
            setDashboardBorder(border);
            setSubscriptionsBorder(none);
            setPaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setPaymentMethodBorder(none);
            setUserDashboardContent(userContent);
        } else if (entry === 'subscriptions') {
            setDashboardBorder(none);
            setSubscriptionsBorder(border);
            setPaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setPaymentMethodBorder(none);
            setUserDashboardContent(subscriptionsContent);
        } else if (entry === 'paymentLinks') {
            setDashboardBorder(none);
            setSubscriptionsBorder(none);
            setPaymentLinksBorder(border);
            setAllPaymentsBorder(none);
            setPaymentMethodBorder(none);
            setUserDashboardContent(paymentLinksContent);
        } else if (entry === 'allPayments') {
            setDashboardBorder(none);
            setSubscriptionsBorder(none);
            setPaymentLinksBorder(none);
            setAllPaymentsBorder(border);
            setPaymentMethodBorder(none);
            setUserDashboardContent(chargesContent);
        } else if (entry === 'paymentMethod') {
            setDashboardBorder(none);
            setSubscriptionsBorder(none);
            setPaymentLinksBorder(none);
            setAllPaymentsBorder(none);
            setPaymentMethodBorder(border);
            setUserDashboardContent(paymentMethodContent);
        }
    }

    const userDashboardBody = (
        <>
            <div className={"admin-dashboard-entries"}>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => handleDashboardEntryClick('dashboard')}
                     style={{border: dashboardBorder}}>Dashboard
                </div>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => handleDashboardEntryClick('subscriptions')}
                     style={{border: subscriptionsBorder}}>Subscriptions
                </div>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => handleDashboardEntryClick('paymentLinks')}
                     style={{border: paymentLinksBorder}}>Payment Links
                </div>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => handleDashboardEntryClick('allPayments')}
                     style={{border: allPaymentsBorder}}>All Payments
                </div>
                <div className={"admin-dashboard-entry dashboard-link"}
                     onClick={() => handleDashboardEntryClick('paymentMethod')}
                     style={{border: paymentMethodBorder}}>Payment Method
                </div>
            </div>
            {
                userError ?
                    <div>Error loading user dashboard content</div>
                    :
                    userDashboardContent
            }
        </>
    );


    return (
        <div className={"admin page"}>
            <Header/>
            {!loading && !userError ?
                <div className={"small-heading"}>Hello, {userInfo.firstName}!</div>
                :
                <div className={"small-heading"}>Hello</div>

            }
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"}>
                    {loading ?
                        <Loading text={"Fetching User Info"}/>
                        :
                        savingCardInfo ? <Loading text={"Saving Card"}/>
                            :
                            userError ?
                                <div>Error loading user content</div>
                                :
                                userDashboardBody
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default UserDashboard;