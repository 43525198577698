import fullLogo from '../images/FullLogo.png';
import facebook from '../images/facebook.png';
import instagram from '../images/instagram.png';
import youtube from '../images/youtube.png';
import twitter from '../images/twitter.png';
import dmca from '../images/dmca.png'
import {useNavigate} from "react-router-dom";

function Footer() {
    const navigate = useNavigate();
    const year = new Date().getFullYear();

    return (
        <footer className={"main-footer"}>
            <div className={"footer-content row"}>
                <div className={"full-logo-wrapper"}>
                    <img className={"full-logo"} src={fullLogo} alt={"Rexius Algorithms Logo"} onClick={() => {navigate("/"); window.scrollTo({top: 0})}}/>
                </div>
                <div className={"quick-links row"}>
                    <div className={"footer-links"}>
                        <div className={"footer-link-heading"}>Links</div>
                        <a className={"footer-link"} href={"/"}>Home</a>
                        <a className={"footer-link"} href={"/about"}>About Us</a>
                        <a className={"footer-link"} href={"/services"}>Services</a>
                    </div>
                    <div className={"footer-links"}>
                        <div className={"footer-link-heading"} style={{visibility: "hidden"}}>Links</div>
                        <a className={"footer-link"} href={"/pricing"}>Pricing</a>
                        <a className={"footer-link"} href={"/contact"}>Contact Us</a>
                        <a className={"footer-link"} href={"/blogs"}>Blogs</a>
                    </div>
                    <div className={"footer-links"}>
                        <div className={"footer-link-heading"}>Legal</div>
                        <a className={"footer-link"} href={"/privacy"}>Privacy Policy</a>
                        <a className={"footer-link"} href={"/terms"}>Terms & Conditions</a>
                    </div>
                    <div className={"footer-links footer-links-social"}>
                        <div className={"footer-link-heading"}>Social</div>
                        <a className={"social-icon-wrapper"} href="https://www.facebook.com/RexiusAlgorithms"
                           target="_blank" rel="noopener noreferrer">
                            <img className={"social-icon"} src={facebook} alt={"facebook"}/>
                        </a>
                        <a className={"social-icon-wrapper"} href="https://www.instagram.com/rexiusalgorithms"
                           target="_blank" rel="noopener noreferrer">
                            <img className={"social-icon"} src={instagram} alt={"instagram"}/>
                        </a>
                        <a className={"social-icon-wrapper"} href="https://www.youtube.com/@RexiusAlgorithms" target="_blank"
                           rel="noopener noreferrer">
                            <img className={"social-icon"} src={youtube} alt={"youtube"}/>
                        </a>
                        <a className={"social-icon-wrapper"} href="https://www.twitter.com/RexiusAlgos"
                           target="_blank" rel="noopener noreferrer">
                            <img className={"social-icon"} src={twitter} alt={"twitter"}/>
                        </a>
                    </div>
                </div>
            </div>
            <img className={"dmca"} src={dmca} alt={"dmca"}/>
            <div className={"copyright"}>© {year} Rexius Algorithms LLC. All Rights Reserved.</div>
        </footer>
    )
}

export default Footer;