import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {resetPassword} from "../service/ResetPasswordService";
import Loading from "./Loading";

function ResetPassword() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [confirmationToken, setConfirmationToken] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const confirmationToken = searchParams.get("reset");
        setConfirmationToken(confirmationToken);
    }, []);

    function validatePassword() {
        // Regex explanation:
        // ^                 : start of string
        // (?=.*[a-z])       : at least one lowercase letter
        // (?=.*[A-Z])       : at least one uppercase letter
        // (?=.*\d)          : at least one number
        // .{8,}             : at least 8 characters
        // $                 : end of string
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

        return regex.test(password);
    }

    const sentForm = (
        <div className="content">
            <div className={"no-account"}>Your password has been reset.</div>
            <div className={"no-account"}>Please click on the link below to log in.</div>
            <div className={"click-here"} onClick={() => navigate("/login")}>Log in</div>
            <div className={"click-here"} onClick={() => navigate("/")}>Go to main page</div>
        </div>
    )

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validatePassword()) {
            window.alert('Password must be at least 8 characters long and must include at least 1 uppercase letter, 1 lowercase letter, and 1 number.')
        } else if (password !== confirmPassword) {
            window.alert('Passwords do not match.')
        } else {
            setLoading(true);
            try {
                resetPassword(email, password, confirmPassword, confirmationToken)
                    .then(resetEmailResponse => {
                        if (resetEmailResponse === true) {
                            setSent(true);
                        } else {
                            window.alert("Could not reset password.")
                        }
                        setLoading(false)
                        // navigate("/account");
                    })
                    .catch(error => {
                        console.error("Error resetting password:", error);
                        setLoading(false);
                    });

            } catch (error) {
                console.error('Password reset failed:', error);
                setLoading(false);
            }
        }
    };

    const resetForm = (
        <form onSubmit={handleSubmit} className={"slide-in-right"}>
            <input className={"contact-form-input"}
                   required
                   type={"email"}
                   placeholder={"Email"}
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"New Password"}
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"Confirm Password"}
                   value={confirmPassword}
                   onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className={"send-wrapper mobile-margin-bottom-0"}>
                <button type={"submit"} className="send">Submit</button>
            </div>
        </form>
    )

    return (
        <div className={"login page"}>
            <Header/>
            <div className={"page-heading"}>PASSWORD</div>
            <div className={"content"}>
                <div className={"section enclosed row"}>
                    <div className={"half-section login-form"}>
                        <div className={"section-heading"}>Reset Password</div>
                        {loading ? <Loading text={"Sending Reset Email"}/>
                            :
                            !sent ?
                                resetForm
                                :
                                sentForm
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ResetPassword;