import ContactForm from "./ContactForm";
import Header from "./Header";
import Footer from "./Footer";

function Contact() {
    return (
        <div className={"contact page"}>
            <Header/>
            <div className={"page-heading"}>CONTACT US</div>
            <div className={"content"}>
                <ContactForm contact={true}/>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact;