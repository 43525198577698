import {refreshToken} from "./AuthService";
import {properties} from "../properties";

export function fetchDashboardUser(firstCall) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('raAccessToken').replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem('raAccessToken', JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchDashboardUser(false).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem("raAccessToken");
                        localStorage.removeItem("raRefreshToken");
                        reject(new Error("Failed to refresh token."));
                    });
                } else {
                    reject(new Error("Failed to fetch subscription."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/api/v1/dashboard/get-user", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function fetchUserDashboardSubscription(firstCall, subscriptionId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('raAccessToken').replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem('raAccessToken', JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchUserDashboardSubscription(false, subscriptionId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem("raAccessToken");
                        localStorage.removeItem("raRefreshToken");
                        reject(new Error("Failed to refresh token."));
                    });
                } else {
                    reject(new Error("Failed to fetch subscription."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/api/v1/dashboard/get-subscription?subscriptionId=" + subscriptionId, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function editPaymentMethod(firstCall, paymentMethodId) {
    console.log(paymentMethodId)
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('raAccessToken').replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem('raAccessToken', JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        editPaymentMethod(false, paymentMethodId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem("raAccessToken");
                        localStorage.removeItem("raRefreshToken");
                        reject(new Error("Failed to refresh token."));
                    });
                } else {
                    reject(new Error("Failed to edit payment method."));
                }
            }
        };
        xhttp.open("POST", properties.host + "/api/v1/dashboard/edit-payment-method", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        const paymentMethod = {
            paymentMethodId: paymentMethodId
        };

        xhttp.send(JSON.stringify(paymentMethod));
    });
}

export function fetchUserDashboardPaymentLink(firstCall, paymentLinkId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('raAccessToken').replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem('raAccessToken', JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchUserDashboardPaymentLink(false, paymentLinkId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem("raAccessToken");
                        localStorage.removeItem("raRefreshToken");
                        reject(new Error("Failed to refresh token."));
                    });
                } else {
                    reject(new Error("Failed to fetch payment link."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/api/v1/dashboard/get-payment-link?paymentLinkId=" + paymentLinkId, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}


export function fetchUserDashboardCharge(firstCall, chargeId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('raAccessToken').replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem('raAccessToken', JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchUserDashboardCharge(false, chargeId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem("raAccessToken");
                        localStorage.removeItem("raRefreshToken");
                        reject(new Error("Failed to refresh token."));
                    });
                } else {
                    reject(new Error("Failed to fetch charge."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/api/v1/dashboard/get-charge?chargeId=" + chargeId, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}