import Header from "./Header";
import Loading from "./Loading";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {unixTimestampToDate} from "../service/DateService";
import {useNavigate} from "react-router-dom";
import {fetchAdminDashboardPaymentLink} from "../service/AdminService";

function AdminPaymentLink() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const path = window.location.pathname.split("/");
    const paymentLinkId = path[path.length - 1];
    const [paymentLinkError, setPaymentLinkError] = useState(false);

    const [paymentLinkInfo, setPaymentLinkInfo] = useState({
        id: "1234",
        name: "test",
        description: "test description",
        firstName: "Brett",
        lastName: "Rexius",
        email: "brettrexius@gmail.com",
        created: "5/2/2024",
        amount: 10,
        active: true,
        cardNo: 4242,
        currentPeriodStart: 1234,
        currentPeriodEnd: 1234

    });

    useEffect(() => {
        if (!localStorage.getItem("raAccessToken")) {
            navigate("/login");
        }
        try {
            setLoading(true)
            fetchAdminDashboardPaymentLink(true, paymentLinkId)
                .then(paymentLinkInfoResponse => {
                    setPaymentLinkInfo(paymentLinkInfoResponse);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading paymentLink content:", error);
                    setPaymentLinkError(true);
                    setLoading(false);
                });

        } catch (error) {
            console.error("Error loading paymentLink content:", error);
            setPaymentLinkError(true);
            setLoading(false);
        }
    }, []);


    const paymentLinkContent = (
        <div className={"payment-link-content"}>
            <div className={"payment-link-subheading"}>{paymentLinkInfo.name}</div>
            {/*<div className={"payment-link-amount"}>${paymentLinkInfo.amount}</div>*/}
            <div className={"payment-link-infos"}>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Name</div>
                    <div className={"payment-link-info-content"}>{paymentLinkInfo.name}</div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Description</div>
                    <div className={"payment-link-info-content"}>{paymentLinkInfo.description}</div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Amount</div>
                    <div className={"payment-link-info-content"}>${paymentLinkInfo.amount}</div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Created</div>
                    <div className={"payment-link-info-content"}>{unixTimestampToDate(paymentLinkInfo.created)}</div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Stripe URL</div>
                    <div className={"payment-link-info-content"}><a href={paymentLinkInfo.url} target="_blank" rel="noopener noreferrer" className={"dashboard-link"}>{paymentLinkInfo.url}</a></div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Status</div>
                    <div className={"payment-link-info-content"}>{paymentLinkInfo.paid ? 'Paid' : 'Active'}</div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Client Name</div>
                    <div className={"payment-link-info-content"}><a href={"/admin/user/" + paymentLinkInfo.customerId}
                                                                    className={"dashboard-link"}>{paymentLinkInfo.firstName} {paymentLinkInfo.lastName}</a></div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Client Email</div>
                    <div className={"payment-link-info-content"}><a href={"/admin/user/" + paymentLinkInfo.customerId}
                                                                    className={"dashboard-link"}>{paymentLinkInfo.email}</a></div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>ID</div>
                    <div className={"payment-link-info-content"}>{paymentLinkInfo.id}</div>
                </div>
            </div>
            <div className={"send-wrapper mobile-margin-bottom-0"} style={{textAlign: 'center'}}>
                <button onClick={() => navigate("/admin")} className="send">Account</button>
            </div>
        </div>
    );

    return (
        <div className={"admin page"}>
            <Header/>
            <div className={"small-heading"}>PAYMENT LINK</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"}>
                    {loading ?
                        <Loading text={"Fetching payment link Info"}/>
                        :
                        paymentLinkError ?
                            <div>Error loading paymentLink content</div>
                            :
                            paymentLinkContent
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AdminPaymentLink;