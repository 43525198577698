import './App.css';
import './Responsive.css';
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Pricing from "./components/Pricing";
import Contact from "./components/Contact";
import TaskAutomation from "./components/sub-service/TaskAutomation";
import CommunicationAutomation from "./components/sub-service/CommunicationAutomation";
import FinancialAutomation from "./components/sub-service/Financial Automation";
import WorkflowAutomation from "./components/sub-service/WorkflowAutomation";
import CustomAutomation from "./components/sub-service/CustomAutomation";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Blogs from "./components/Blogs";
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import AdminDashboard from "./components/AdminDashboard";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import ChangePassword from "./components/ChangePassword";
import AdminSubscription from "./components/AdminSubscription";
import AdminUser from "./components/AdminUser";
import AdminPaymentLink from "./components/AdminPaymentLink";
import AdminCharge from "./components/AdminCharge";
import UserDashboard from "./components/UserDashboard";
import UserCharge from "./components/UserCharge";
import UserSubscription from "./components/UserSubscription";
import UserPaymentLink from "./components/UserPaymentLink";
import Blog1 from "./components/blog/Blog1";
import Blog2 from "./components/blog/Blog2";
import Blog3 from "./components/blog/Blog3";
import Blog4 from "./components/blog/Blog4";
import Blog5 from "./components/blog/Blog5";
import Blog6 from "./components/blog/Blog6";
import Blog7 from "./components/blog/Blog7";
import Blog8 from "./components/blog/Blog8";
import Blog9 from "./components/blog/Blog9";
import Blog10 from "./components/blog/Blog10";
import Blog11 from "./components/blog/Blog11";
import Blog12 from "./components/blog/Blog12";
import Blog13 from "./components/blog/Blog13";
import Blog14 from "./components/blog/Blog14";
import Blog15 from "./components/blog/Blog15";
import Blog16 from "./components/blog/Blog16";
import Blog17 from "./components/blog/Blog17";
import Blog18 from "./components/blog/Blog18";
import Blog19 from "./components/blog/Blog19";
import Blog20 from "./components/blog/Blog20";
import Blog21 from "./components/blog/Blog21";
import Blog22 from "./components/blog/Blog22";
import Blog23 from "./components/blog/Blog23";

function App() {
    return (
        <Routes>
            <Route path={"/"} element={<Home/>}/>
            <Route path={"/about/*"} element={<About/>}/>
            <Route path={"/services/*"} element={<Services/>}/>
            <Route path={"/services/task/*"} element={<TaskAutomation/>}/>
            <Route path={"/services/communication/*"} element={<CommunicationAutomation/>}/>
            <Route path={"/services/financial/*"} element={<FinancialAutomation/>}/>
            <Route path={"/services/workflow/*"} element={<WorkflowAutomation/>}/>
            <Route path={"/services/custom/*"} element={<CustomAutomation/>}/>
            <Route path={"/pricing/*"} element={<Pricing/>}/>
            <Route path={"/contact/*"} element={<Contact/>}/>
            <Route path={"/privacy/*"} element={<Privacy/>}/>
            <Route path={"/terms/*"} element={<Terms/>}/>
            <Route path={"/blogs/*"} element={<Blogs/>}/>
            <Route path={"/blogs/1/*"} element={<Blog1/>}/>
            <Route path={"/blogs/2/*"} element={<Blog2/>}/>
            <Route path={"/blogs/3/*"} element={<Blog3/>}/>
            <Route path={"/blogs/4/*"} element={<Blog4/>}/>
            <Route path={"/blogs/5/*"} element={<Blog5/>}/>
            <Route path={"/blogs/6/*"} element={<Blog6/>}/>
            <Route path={"/blogs/7/*"} element={<Blog7/>}/>
            <Route path={"/blogs/8/*"} element={<Blog8/>}/>
            <Route path={"/blogs/9/*"} element={<Blog9/>}/>
            <Route path={"/blogs/10/*"} element={<Blog10/>}/>
            <Route path={"/blogs/11/*"} element={<Blog11/>}/>
            <Route path={"/blogs/12/*"} element={<Blog12/>}/>
            <Route path={"/blogs/13/*"} element={<Blog13/>}/>
            <Route path={"/blogs/14/*"} element={<Blog14/>}/>
            <Route path={"/blogs/15/*"} element={<Blog15/>}/>
            <Route path={"/blogs/16/*"} element={<Blog16/>}/>
            <Route path={"/blogs/17/*"} element={<Blog17/>}/>
            <Route path={"/blogs/18/*"} element={<Blog18/>}/>
            <Route path={"/blogs/19/*"} element={<Blog19/>}/>
            <Route path={"/blogs/20/*"} element={<Blog20/>}/>
            <Route path={"/blogs/21/*"} element={<Blog21/>}/>
            <Route path={"/blogs/22/*"} element={<Blog22/>}/>
            <Route path={"/blogs/23/*"} element={<Blog23/>}/>
            <Route path={"/login/*"} element={<LogIn/>}></Route>
            <Route path={"/signup/*"} element={<SignUp/>}></Route>
            <Route path={"/admin/*"} element={<AdminDashboard/>}></Route>
            <Route path={"/admin/subscription/*"} element={<AdminSubscription/>}></Route>
            <Route path={"/admin/payment-link/*"} element={<AdminPaymentLink/>}></Route>
            <Route path={"/admin/charge/*"} element={<AdminCharge/>}></Route>
            <Route path={"/admin/user/*"} element={<AdminUser/>}></Route>
            <Route path={"/dashboard/charge/*"} element={<UserCharge/>}></Route>
            <Route path={"/dashboard/subscription/*"} element={<UserSubscription/>}></Route>
            <Route path={"/dashboard/payment-link/*"} element={<UserPaymentLink/>}></Route>
            <Route path={"/account/*"} element={<UserDashboard/>}></Route>
            <Route path={"/forgot-password/*"} element={<ForgotPassword/>}></Route>
            <Route path={"/reset-password/*"} element={<ResetPassword/>}></Route>
            <Route path={"/change-password/*"} element={<ChangePassword/>}></Route>

            {/*<Route path={"/change-password"} element={<ChangePassword/>}></Route>*/}
            {/*<Route path={"/forgot-password"} element={<ForgotPassword/>}></Route>*/}
            {/*<Route path={"/reset-password"} element={<ResetPassword/>}></Route>*/}
            <Route path={'*'} element={<Navigate to="/"/>}/>
        </Routes>
    );
}

export default App;
