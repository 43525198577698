import smallLogo from '../images/LogoSmall.png'
import logoVideo from "../videos/LogoFast.mp4";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from '@fortawesome/free-regular-svg-icons';
import {getJwtRole, isUserLoggedIn, logout} from "../service/AuthService";


function Header(props) {

    const navigate = useNavigate();
    const [logoVisible, setLogoVisible] = useState("hidden");
    const [logoVideoVisible, setLogoVideoVisible] = useState("visible");
    const [role, setRole] = useState(getJwtRole());
    const slideInTop = props.home ? " slide-in-top" : "";

    function displayLogo() {
        if (!props.home) {
            return <img onClick={() => navigate("/")} src={smallLogo} alt={"small-logo"} className={"small-logo"}/>
        } else {
            return (
                <div>
                    <video style={{visibility: logoVideoVisible}} className={"logo-video"} playsInline autoPlay muted onEnded={() => {
                        setLogoVideoVisible("hidden");
                        setLogoVisible("visible")
                    }}>
                        <source src={logoVideo} type="video/mp4"/>
                    </video>
                    <img style={{visibility: logoVisible}} onClick={() => navigate("/")} src={smallLogo}
                         alt={"small-logo"} className={"small-logo"}/>
                </div>
            )
        }
    }

    function handleDropDownClick(command) {
        handleIconClick();
        if (command === "logout") {
            const out = logout();

            if (out) {
                // console.log(out)
                // console.log(props)
                // props.setLoggedIn(false);
                navigate("/login");
            } else {
                console.log("error logging out");
            }
            // localStorage.removeItem('access-token');
            // navigate("/");
        } else if (command === "account") {
            role === 'ROLE_ADMIN' ? navigate("/admin") : navigate("/account");
        } else if (command === "login") {
            navigate("/login");
        } else {
            navigate("/signup");
        }
    }

    const [dropDownHeight, setDropDownHeight] = useState('0');
    const dropDown = isUserLoggedIn() ? (
        <div className={"dropdown"} style={{height: dropDownHeight}}>
            <div className={"dropdown-entry"} onClick={() => handleDropDownClick("account")}>Account</div>
            <div className={"dropdown-entry"} onClick={() => handleDropDownClick("logout")}>Log Out</div>
        </div>
    ) : (
        <div className={"dropdown"} style={{height: dropDownHeight}}>
            <div className={"dropdown-entry"} onClick={() => handleDropDownClick("login")}>Log In</div>
            <div className={"dropdown-entry"} onClick={() => handleDropDownClick("signup")}>Sign Up</div>
        </div>
    )

    function handleIconClick() {
        setDropDownHeight(dropDownHeight === '0' ? '80px' : '0');
    }

    const [middleBorderColor, setMiddleBorderColor] = useState('#b619ff');
    const [transformTop, setTransformTop] = useState('none');
    const [transformBottom, setTransformBottom] = useState('none');
    const [slideMenuWidth, setSlideMenuWidth] = useState('0');
    const [slideLinkOpacity, setSlideLinkOpacity] = useState('0');

    function handleHamburgerClick() {
        setMiddleBorderColor(middleBorderColor === '#b619ff' ? '#000416' : '#b619ff');
        setTransformTop(transformTop === 'none' ? 'rotate(45deg)' : 'none');
        setTransformBottom(transformBottom === 'none' ? 'rotate(-45deg)' : 'none');
        setSlideMenuWidth(slideMenuWidth === '0' ? '100%' : '0');
        setSlideLinkOpacity(slideLinkOpacity === '0' ? '1' : '0');
    }

    return (
        <header className={"main-header"}>
            {displayLogo()}
            <div className={"nav-links"}>
                <a href={"/"} className={"nav-link"}>Home</a>
                <a href={"/about"} className={"nav-link nav-link-hidden"}>About Us</a>
                <a href={"/services"} className={"nav-link nav-link-hidden"}>Services</a>
                <a href={"/pricing"} className={"nav-link nav-link-hidden"}>Pricing</a>
                <a href={"/contact"} className={"nav-link nav-link-hidden"}>Contact Us</a>
                <div className={"user-icon-container nav-link-hidden"}>
                    <div className={"user-icon"} onClick={() => handleIconClick()}>
                        <FontAwesomeIcon icon={faUser}/>
                    </div>
                    {dropDown}
                </div>
            </div>
            <div className={"hamburger"} onClick={() => handleHamburgerClick()}>
                <div className={"hamburger-line"} style={{transform: transformTop}}></div>
                <div className={"hamburger-line"} style={{borderColor: middleBorderColor}}></div>
                <div className={"hamburger-line"} style={{transform: transformBottom}}></div>
            </div>
            <div className={"slide-menu"} style={{width: slideMenuWidth}}>
                <div className={"slide-links"} style={{opacity: slideLinkOpacity}}>
                    <div className={"slide-link"}>
                        <a href={"/"} className={"slide-link-a"}>Home</a>
                    </div>
                    <div className={"slide-link"}>
                        <a href={"/about"} className={"slide-link-a"}>About Us</a>
                    </div>
                    <div className={"slide-link"}>
                        <a href={"/services"} className={"slide-link-a"}>Services</a>
                    </div>
                    <div className={"slide-link"}>
                        <a href={"/pricing"} className={"slide-link-a"}>Pricing</a>
                    </div>
                    <div className={"slide-link"}>
                        <a href={"/contact"} className={"slide-link-a"}>Contact Us</a>
                    </div>
                    <div className={"slide-link"}>
                        {!isUserLoggedIn() ?
                            <a href={"/login"} className={"slide-link-a"}>Log In</a>
                            :
                            role === 'ROLE_ADMIN' ?
                                <a href={"/admin"} className={"slide-link-a"}>Account</a>
                                :
                                <a href={"/account"} className={"slide-link-a"}>Account</a>
                        }
                    </div>
                    <div className={"slide-link"}>
                        {!isUserLoggedIn() ?
                            <a href={"/signup"} className={"slide-link-a"}>Sign Up</a>
                            :
                            <div className={"slide-link-a"} onClick={() => {
                                handleDropDownClick("logout");
                                handleHamburgerClick()
                            }}>Log Out</div>
                        }
                    </div>
                </div>
            </div>
            <div className={"heading " + slideInTop}>
                <span className="line"></span>
                <span className="title">REXIUS ALGORITHMS</span>
                <span className="line"></span>
            </div>
        </header>
    )
}

export default Header;