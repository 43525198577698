import Header from "./Header";
import blog1 from "../images/blog-1.jpeg";
import blog2 from "../images/blog-2.jpeg";
import blog3 from "../images/blog-3.png";
import blog4 from "../images/blog-4.jpeg";
import blog5 from "../images/blog-5.png";
import blog6 from "../images/blog-6.png";
import blog7 from "../images/blog-7.jpeg";
import blog8 from "../images/blog-8.jpeg";
import blog9 from "../images/blog-9.png";
import blog10 from "../images/blog-10.jpeg";
import blog11 from "../images/blog-11.png";
import blog12 from "../images/blog-12.png";
import blog13 from "../images/blog-13.png";
import blog14 from "../images/blog-14.png";
import blog15 from "../images/blog-15.png";
import blog16 from "../images/blog-16.png";
import blog17 from "../images/blog-17.png";
import blog18 from "../images/blog-18.png";
import blog19 from "../images/blog-19.png";
import blog20 from "../images/blog-20.jpeg";
import blog21 from "../images/blog-21.png";
import blog22 from "../images/blog-22.jpeg";
import blog23 from "../images/blog-23.jpeg";

import LazyLoad from "./LazyLoad";
import ContactForm from "./ContactForm";
import Footer from "./Footer";

function Blogs() {
    return (
        <div className={"blogs page"}>
            <Header/>
            <div className={"page-heading"}>BLOGS</div>
            <div className={"content"}>
                <div className={"blogs-page-text"}>
                    <div className={"section open"}>
                        <div className={"blogs-section"}>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog1} alt={"task-automation"}/>
                                <div className={"blog-section-task-name"}>Revolutionize Your Supply Chain A Beginner
                                    Guide to Workflow Automation Solutions
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/1" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog2}
                                     alt={"communication-automation"}/>
                                <div className={"blog-section-task-name"}>Boosting Efficiency: 7 Practical Ways to Use
                                    Workflow Automation for Small Businesses
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/2" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog3}
                                     alt={"financial-automation"}/>
                                <div className={"blog-section-task-name"}>Power of Financial Automation Trading Systems
                                    Predicting Market Trends for Informed Investments
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/3" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog4}
                                     alt={"workflow-automation"}/>
                                <div className={"blog-section-task-name"}>How Communication Automation Solutions is Used
                                    in Customer Service
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/4" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog5}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>Scheduled Task Automation Solutions: How to
                                    Use in Business Operations
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/5" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog6}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>Custom Automation Solutions vs. Generic:
                                    Finding the Ideal Fit for Your Business
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/6" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog7}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>A Detailed Guide to Financial Automation
                                    Trading Systems for Harnessing Efficiency in Fast Markets
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/7" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog8}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>9 Setbacks: The Impact of Missing Daily Emails
                                    and Report Solutions on Your Business
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/8" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog9}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>Enjoy Trading Success: A Comprehensive Guide
                                    to Communication Automation Solutions
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/9" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog10}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>The Impact of Scheduled Task Automation
                                    Solutions on Business Operations
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/10" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog11}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>How to Use an Automated Trading System to
                                    Trade Cryptocurrencies
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/11" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog12}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>Custom Automation Solutions for Supply Chain:
                                    Automating Inventory Management, Order Fulfillment, & Logistics
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/12" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog13}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>Best Financial Automation Trading System: A
                                    Guide to Choosing the Ideal Platform
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/13" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog14}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>What is a Communication Automation Solutions &
                                    How Does it Work?
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/14" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog15}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>14 Tools for Scheduled Task Automation
                                    Solutions You Must Be Using Right Now
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/15" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog16}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>Workflow Automation Solutions Efficiently
                                    Streamline Business Operations
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/16" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog17}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>Workflow Automation for Small Businesses: Make
                                    Your Life Easy
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/17" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog18}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>11 Consequences Automated Stock Trading
                                    Systems Can Save You From
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/18" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog19}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>How Custom Automation Solutions Can Transform
                                    Your Trading Experience
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/19" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog20}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>Innovating Trading: The Rise of Financial
                                    Automation Trading Systems
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/20" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog21}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>Streamlining Your Business Communication: The
                                    Impact of Daily Emails and Report Solutions
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/21" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog22}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>10 Reasons Why Your Company Needs
                                    Communication Automation Solutions
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/22" className="learn-more">Learn More</a>
                                </div>
                            </div>
                            <div className={"blog-section scale-up"}>
                                <img className={"blog-section-blog-image"} src={blog23}
                                     alt={"custom-automation"}/>
                                <div className={"blog-section-task-name"}>Scheduled Task Automation Solutions: A Key to
                                    Increased Productivity and Efficiency
                                </div>
                                <div className={"blog-section-learn-more"}>
                                    <a href="/blogs/23" className="learn-more">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LazyLoad>
                    <ContactForm/>
                </LazyLoad>
            </div>
            <Footer/>
        </div>
    )
}

export default Blogs;