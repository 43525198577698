import {properties} from "../properties";

export function sendContactEmail(name, email, message) {
    return new Promise((resolve, reject) => {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else {
                    if (this.status === 403) {
                        resolve(403);
                    } else {
                        reject(new Error("Failed to send email"));
                    }
                }
            }
        };
        xhttp.open("POST", properties.host + "/api/v1/contact/email", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        const contactEmail = {
            name: name,
            email: email,
            message: message
        };
        xhttp.send(JSON.stringify(contactEmail));
    });
}