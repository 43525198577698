import Header from "../Header";
import Footer from "../Footer";
import task from '../../images/task-automation.png';

function TaskAutomation() {

    return (
        <div className={"sub-service page"}>
            <Header/>
            <div className={"small-heading"}>TASK AUTOMATION</div>
            <div className={"content"}>
                <div className={"sub-service-page-text"}>
                    <img className={"service-section-service-image scale-up margin-bottom-2"} src={task}
                         alt={"task-automation"}/>
                    <div className={"section open slide-in-top"}>
                        <div className={"section-subheading"}>Timely Scheduled Task Automation Solutions</div>
                        <p className={"section-content"}>
                            We specialize in task automation, a vital component in optimizing routine operations. Our
                            cutting-edge solutions are designed to streamline repetitive tasks, saving you time and
                            resources for higher-value activities.
                        </p>
                        <div className={"section-subheading"}>How Task Automation Works</div>
                        <p className={"section-content"}>
                            Task automation involves setting up systems that can execute specific actions autonomously
                            without constant human intervention. This ensures that routine, time-consuming tasks are
                            handled with precision and consistency. Through advanced programming and algorithms, we
                            create bespoke automation solutions tailored to your unique requirements.
                        </p>
                        <div className={"section-subheading"}>Benefits of Task Automation</div>
                        <div className={"section-content"}>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Increased Efficiency</span> – By automating mundane tasks,
                                you
                                eliminate
                                manual labor, allowing you to focus on strategic initiatives. This leads to increased
                                overall productivity and business growth.
                            </div>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Error Reduction</span> – Automated systems perform tasks
                                accurately, virtually eliminating the risk of human error. This leads to higher quality
                                outcomes and improved customer satisfaction.
                            </div>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Time Savings</span> – With task automation, you’ll reclaim
                                valuable
                                time previously spent on repetitive activities. This newfound time can be reinvested in
                                more
                                critical aspects of your business.
                            </div>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Enhanced Productivity</span> – Utilizing automation can
                                optimize
                                workflows and achieve more in less time. This boosts productivity and enables your team
                                to
                                take on more high-impact tasks.
                            </div>
                            If you want to experience the benefits of task automation, contact us today to get
                            started
                            on your journey toward a more efficient and productive business. With Rexius Algorithms,
                            you’re not just automating tasks; you’re revolutionizing your work!
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default TaskAutomation;