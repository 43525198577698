import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {isUserLoggedIn} from "../service/AuthService";
import {changePassword} from "../service/ChangePasswordService";

function ChangePassword() {

    const navigate = useNavigate();

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [loggedIn, setIsLoggedIn] = useState(isUserLoggedIn);

    const [form, setForm] = useState("form");


    useEffect(() => {
        if (!loggedIn) {
            navigate("/login");
        }
    }, [loggedIn]);

    function validatePassword() {
        // Regex explanation:
        // ^                 : start of string
        // (?=.*[a-z])       : at least one lowercase letter
        // (?=.*[A-Z])       : at least one uppercase letter
        // (?=.*\d)          : at least one number
        // .{8,}             : at least 8 characters
        // $                 : end of string
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

        return regex.test(newPassword);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validatePassword()) {
            window.alert('Password must be at least 8 characters long and must include at least 1 uppercase letter, 1 lowercase letter, and 1 number.')
        } else if (newPassword !== confirmationPassword) {
            window.alert('Passwords do not match.')
        } else {
            setLoading(true);
            try {
                changePassword(true, currentPassword, newPassword, confirmationPassword)
                    .then(changePasswordResponse => {
                        if (changePasswordResponse === true) {
                            setForm("success");
                        } else {
                            window.alert("Could not change password.")
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Error changing password:", error);
                        window.alert('Error Changing Password')
                        setLoading(false);
                    });
            } catch (error) {
                console.error("Error changing password:", error);
                window.alert('Error Changing Password')
                setLoading(false);
            }
        }
    };

    return (
        <div className={"login page"}>
            <Header/>
            <div className={"page-heading"}>PASSWORD</div>
            <div className={"content"}>
                <div className={"section enclosed row"}>
                    <div className={"half-section login-form"}>
                        <div className={"section-heading"}>Change Password</div>
                        {form === "form" ?
                            <form onSubmit={handleSubmit} className={"slide-in-right"}>
                                <input className={"contact-form-input"}
                                       required
                                       type={"password"}
                                       placeholder={"Current Password"}
                                       value={currentPassword}
                                       onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                                <input className={"contact-form-input"}
                                       required
                                       type={"password"}
                                       placeholder={"New Password"}
                                       value={newPassword}
                                       onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <input className={"contact-form-input"}
                                       required
                                       type={"password"}
                                       placeholder={"Confirm Password"}
                                       value={confirmationPassword}
                                       onChange={(e) => setConfirmationPassword(e.target.value)}
                                />
                                <div className={"send-wrapper mobile-margin-bottom-0"}>
                                    <button type={"submit"} className="send">Submit</button>
                                </div>
                            </form>
                            :
                            <div className={"nothing-here"}>Password Changed!</div>
                        }
                        <div className={"have-account"}>
                            <a href={"/account"} className={"click-here"}>Go back</a>
                            {/*if admin, reroute to admin page. do this in account component*/}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ChangePassword;