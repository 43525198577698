import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {stripePublishableKey} from "../../constants/Constants";
import {editPaymentMethod} from "../../service/UserService";

// Load your publishable key from the Stripe dashboard
// const stripePromise = loadStripe(stripePublishableKey);

function PaymentMethodForm({setSavingCardInfo, setToggle, toggle, handlePaymentButton, handleDashboardEntryClick, setUserDashboardContent, paymentMethodContent}) {
    const stripe = useStripe();
    const elements = useElements();
    const year = new Date().getFullYear();
    const month = new Date().getMonth();


    // const blockInvalidChar = (e) => ['e', 'E', '+', '-', checkNeg(e.target.value)].includes(e.key) && e.preventDefault();

    // function checkNeg(val) {
    //     return val.length > 0 ? '-' : '';
    // }
    //
    // function handleMonthInput(event) {
    //     const value = event.target.value;
    //     // Ensure the value is within the range and has a maximum of 2 digits
    //     if (value.length > 2) {
    //         event.target.value = value.slice(0, 2);
    //     }
    // }
    //
    // function handleCvvInput(event) {
    //     const value = event.target.value;
    //     // Ensure the value is within the range and has a maximum of 2 digits
    //     if (value.length > 3) {
    //         event.target.value = value.slice(0, 3);
    //     }
    // }
    //
    // function handleYearInput(event) {
    //     const value = event.target.value;
    //     if (value === 'e') {
    //         event.target.value = value.slice(0, value.length - 1);
    //     }
    //     // Ensure the value is within the range and has a maximum of 4 digits
    //     else if (value.length > 4) {
    //         event.target.value = value.slice(0, 4);
    //     }
    // }

    function isDateInvalid(expMonth, expYear) {
        if (expYear === year) {
            if (expMonth <= month) {
                return true;
            }
        }
        return false;
    }

    const handleSendPaymentMethod = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        // const number = cardElement.;
        // let expMonth = document.getElementById("edit-payment-method-exp-month").value;
        // expMonth = parseInt(expMonth, 10);
        // const expYear = document.getElementById("edit-payment-method-exp-year").value;
        // const cvv = document.getElementById("edit-payment-method-cvv").value;

        // if (number === null || number.length !== 16) {
        //     window.alert('Card Number Invalid');
        // } else if (expMonth === null || expMonth.length < 1 || expMonth.length > 2 || expMonth < 1 || expMonth > 12) {
        //     console.log(expMonth.length)
        //     window.alert('Expiration Month Invalid');
        // } else if (expYear === null || expYear.length !== 4 || expYear < year) {
        //     window.alert('Expiration Year Invalid');
        // } else if (cvv === null || cvv.length !== 3) {
        //     window.alert('CVV Invalid');
        // } else if (isDateInvalid(expMonth, expYear))
        //     window.alert('Card Is Expired');
        // else {
            if (!stripe) {
                // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
                return;
            }
            setSavingCardInfo(true);
            // const {error, paymentMethod} = await stripe.createPaymentMethod({
            //     type: 'card',
            //     card: {
            //         number: number,
            //         exp_month: parseInt(expMonth),
            //         exp_year: parseInt(expYear),
            //         cvc: cvv,
            //     },
            // });

            if (error) {
                console.error(error);
                window.alert('Could not update payment method.');
                setSavingCardInfo(false);
            } else {
                try {
                    editPaymentMethod(true, paymentMethod.id)
                        .then(paymentResponse => {
                            if (paymentResponse === true) {
                                window.alert('Payment method updated!');
                                setSavingCardInfo(false);
                                setToggle(!toggle);
                                handleDashboardEntryClick('paymentMethod');
                                setUserDashboardContent(paymentMethodContent);
                            } else {
                                window.alert('Could not update payment method.');
                                setSavingCardInfo(false);
                            }
                        })
                        .catch(error => {
                            console.error("Error loading payment method:", error);
                            window.alert('Could not update payment method.');
                            setSavingCardInfo(false);
                        });

                } catch (error) {
                    console.error("Error loading payment method:", error);
                    window.alert('Could not update payment method.');
                    setSavingCardInfo(false);
                }

            // }

            // sendPaymentMethod(number, expMonth, expYear, cvv);
        }

    }

    const cardElementOptions = {
        style: {
            base: {
                color: 'white',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    return (
        <div className="admin-dashboard-content">
            <div className="section-subheading admin-subheading">Payment Method</div>
            <form onSubmit={handleSendPaymentMethod}>
                <table className="dashboard-table">
                    <thead>
                    <tr className="dashboard-tr">
                        <th className="th-width-50-percent">Card Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="dashboard-tr">
                        <td className="dashboard-td">
                            <CardElement options={cardElementOptions}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="save-wrapper">
                    <button type="submit" className="save">Save</button>
                    <button type="button" className="save" onClick={() => handlePaymentButton('cancel')}>Cancel</button>
                </div>
            </form>
        </div>
    );


    /*
    return (
        <div className={"admin-dashboard-content"}>
            <div className={"section-subheading admin-subheading"}>Payment Method</div>
            <form onSubmit={handleSendPaymentMethod}>
                <table className={"dashboard-table"}>
                    <thead>
                    <tr className={"dashboard-tr"}>
                        <th className={"th-width-50-percent"}>Number</th>
                        <th className={"th-width-30-percent"}>Expiration</th>
                        <th className={"th-width-25-percent"}>CVV</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={"dashboard-tr"}>
                        <td className={"dashboard-td"}><input className={"edit-payment-input"}
                                                              id={"edit-payment-method-number"}
                                                              type={"number"}
                                                              placeholder={"16-digit card number"}/></td>
                        <td className={"dashboard-td"}><input className={"edit-payment-input"}
                                                              id={"edit-payment-method-exp-month"}
                                                              type={"number"}
                                                              placeholder={"MM"}
                                                              min={1}
                                                              max={12}
                                                              onInput={handleMonthInput}
                                                              onKeyDown={blockInvalidChar}/>/
                            <input className={"edit-payment-input"}
                                   id={"edit-payment-method-exp-year"}
                                   type={"number"}
                                   placeholder={"YYYY"}
                                   min={year}
                                   onInput={handleYearInput}
                                   onKeyDown={blockInvalidChar}/></td>
                        <td className={"dashboard-td"}><input className={"edit-payment-input"}
                                                              id={"edit-payment-method-cvv"}
                                                              type={"number"}
                                                              placeholder={"***"}
                                                              onInput={handleCvvInput}
                                                              onKeyDown={blockInvalidChar}/></td>
                    </tr>
                    </tbody>
                </table>
                <div className={"save-wrapper"}>
                    <button type={"submit"} className="save">Save</button>
                    <button className="save" onClick={() => handlePaymentButton('cancel')}>Cancel</button>
                </div>
            </form>

        </div>
    );*/
}

export default PaymentMethodForm;

// const PaymentMethodForm = () => {
//     const stripe = useStripe();
//     const elements = useElements();
//
//     const handleSubmit = async (event) => {
//         event.preventDefault();
//
//         if (!stripe || !elements) {
//             // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
//             return;
//         }
//
//         const cardElement = elements.getElement(CardElement);
//
//         const { error, paymentMethod } = await stripe.createPaymentMethod({
//             type: 'card',
//             card: cardElement,
//         });
//
//         if (error) {
//             console.error(error);
//         } else {
//             console.log('PaymentMethod:', paymentMethod);
//             // Send paymentMethod.id to your server
//             fetch('/create-payment-method', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
//             })
//                 .then((response) => response.json())
//                 .then((data) => {
//                     console.log(data);
//                 })
//                 .catch((error) => {
//                     console.error('Error:', error);
//                 });
//         }
//     };
//
//     return (
//         <Elements stripe={stripePromise}>
//             <form onSubmit={handleSubmit}>
//                 <CardElement />
//                 <button type="submit" disabled={!stripe}>
//                     Create Payment Method
//                 </button>
//             </form>
//         </Elements>
//     );
// };
//
// export default PaymentMethodForm;
