import Header from "./Header";
import Loading from "./Loading";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {unixTimestampToDateAndTime} from "../service/DateService";
import {useNavigate} from "react-router-dom";
import {fetchUserDashboardCharge} from "../service/UserService";

function UserCharge() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const path = window.location.pathname.split("/");
    const chargeId = path[path.length - 1];
    const [chargeError, setChargeError] = useState(false);

    const [chargeInfo, setChargeInfo] = useState({
        id: "1234",
        firstName: "Brett",
        lastName: "Rexius",
        email: "brettrexius@gmail.com",
        date: "5/2/2024",
        amount: 10,
        userId: 1,
        receiptUrl: "url"
    });

    useEffect(() => {
        if (!localStorage.getItem("raAccessToken")) {
            navigate("/login");
        }
        try {
            setLoading(true)
            fetchUserDashboardCharge(true, chargeId)
                .then(chargeInfoResponse => {
                    setChargeInfo(chargeInfoResponse);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading charge content:", error);
                    setChargeError(true);
                    setLoading(false);
                });

        } catch (error) {
            console.error("Error loading charge content:", error);
            setChargeError(true);
            setLoading(false);
        }
    }, []);

    const chargeContent = (
        <div className={"payment-link-content"}>
            <div className={"payment-link-infos"}>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Date</div>
                    <div className={"payment-link-info-content dashboard-td-inner"}>{unixTimestampToDateAndTime(chargeInfo.date)}</div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Amount</div>
                    <div className={"payment-link-info-content dashboard-td-inner"}>${chargeInfo.amount}</div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Email</div>
                    <div className={"payment-link-info-content"}>{chargeInfo.email}</div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Card</div>
                    <div className={"payment-link-info-content"}>{chargeInfo.lastFour !== 0 ? chargeInfo.lastFour : '****'}</div>
                </div>
                <div className={"payment-link-info"}>
                    <div className={"payment-link-info-heading"}>Receipt</div>
                    <div className={"payment-link-info-content dashboard-td-inner"}><a href={chargeInfo.receiptUrl} target="_blank" rel="noopener noreferrer" className={"dashboard-link"}>{chargeInfo.id}</a></div>
                </div>
            </div>
            <div className={"send-wrapper mobile-margin-bottom-0"} style={{textAlign: 'center'}}>
                <button onClick={() => navigate("/account")} className="send">Account</button>
            </div>
        </div>
    );

    return (
        <div className={"admin page"}>
            <Header/>
            <div className={"small-heading"}>CHARGE</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"}>
                    {loading ?
                        <Loading text={"Fetching charge info"}/>
                        :
                        chargeError ?
                            <div>Error loading charge content</div>
                            :
                            chargeContent
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default UserCharge;