import Header from "../Header";
import Footer from "../Footer";
import communication from "../../images/communication-automation.png";

function CommunicationAutomation() {

    return (
        <div className={"sub-service page"}>
            <Header/>
            <div className={"small-heading"}>COMMUNICATION AUTOMATION</div>
            <div className={"content"}>
                <div className={"sub-service-page-text"}>
                    <img className={"service-section-service-image scale-up margin-bottom-2"} src={communication}
                         alt={"task-automation"}/>
                    <div className={"section open slide-in-top"}>
                        <div className={"section-subheading"}>Top-Notch Communication Automation Solutions</div>
                        <p className={"section-content"}>
                            Poor communication can cause setbacks. Improve your trading communication strategies with
                            Rexius Algorithms’ advanced automated communication solutions. Our cutting-edge solutions
                            are designed to optimize your outreach efforts, ensuring timely and effective interactions
                            with clients and stakeholders.
                        </p>
                        <div className={"section-subheading"}>How Communication Automation Works</div>
                        <p className={"section-content"}>
                            Our communication automation service involves utilizing technology to streamline critical
                            communication processes. This includes automating alerts, order confirmations, execution of
                            messages, and other forms of messaging, guaranteeing that important information reaches you
                            timely and accurately. Through precise scheduling and tailored messaging, we assist you in
                            maintaining seamless connections with your network.
                        </p>
                        <div className={"section-subheading"}>Benefits of Communication Automation</div>
                        <div className={"section-content"}>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Real-Time Trade Notifications</span> – Receive instant
                                notifications for executed trades, keeping you updated on market movements and trade
                                outcomes.
                            </div>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Efficient Order Confirmations</span> – Automate the
                                confirmation process for placed orders, ensuring quick and accurate execution.
                            </div>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Personalized Client Updates</span> – Tailor communication to
                                individual client preferences, enhancing client satisfaction and trust.
                            </div>
                            <div className={"bullet-point"}>
                                <span className={"bold"}>• Timely Market Insights</span> – Stay informed with automated
                                updates on trends and analysis, giving you a competitive edge.
                            </div>
                            For automated communication strategies tailored to your needs, contact us today. Start your
                            journey towards more efficient and impactful trading outreach. With us, you can
                            revolutionize how you connect with your partners, ensuring you never miss a beat.
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default CommunicationAutomation;