import Header from "./Header";
import Footer from "./Footer";
import lineRobot from '../images/line-robot.png'
import twoRobots from "../images/two-robots.png";
import computer from '../images/computer.png';
import largeComputer from '../images/LargeComputer.png';
import robotFace from '../images/robot-face.png';
import ContactForm from "./ContactForm";
import LazyLoad from "./LazyLoad";

function About() {

    return (
        <div className={"about page"}>
            <Header/>
            <div className={"page-heading"}>ABOUT US</div>
            <div className={"content"}>
                <div className={"about-page-text"}>
                    <div className={"about-section open center row"}>
                        <div className={"half-section"}>
                            <img src={lineRobot} alt={"line-robot"} className={"enclosed-image"}/>
                        </div>
                        <div className={"half-section"}>
                            <div className={"section-heading"}>Our Journey</div>
                            <p className={"section-content"}>
                                Founded in 2023 by Brett Rexius, our journey began with a personal mission to simplify
                                daily
                                tasks. This led to the creation of a unique algorithm that effortlessly processes surf
                                data.
                                Recognizing the universal potential of automation, we expanded our focus to include
                                complicated systems like automated trading, where data-driven decisions drive financial
                                success.
                                <br/>
                                <br/>
                                Catering to a diverse clientele, from tech enthusiasts to small business owners, we
                                offer
                                various services. From basic cron jobs to sophisticated algorithmic trading, we take
                                charge
                                of hosting and maintenance, ensuring a hassle-free experience for our clients.
                                <br/>
                                <br/>
                                Our streamlined process begins with a contact form submission and a personalized
                                consultation to determine specific automation needs. We pride ourselves on transforming
                                complex concepts into practical solutions, whether for stock and cryptocurrency trading
                                or
                                data reporting.
                                <br/>
                                <br/>
                                Bringing Innovation to the System - What sets Rexius Algorithms apart is our commitment
                                to
                                innovation and our dedication to service. Our cutting-edge solutions are designed to
                                help
                                you save time, providing unmatched precision and efficiency. Through meticulous research
                                and
                                development, we create algorithms that adapt seamlessly to the dynamic market
                                environment.
                                With a relentless pursuit of excellence, we're dedicated to redefining what's possible
                                to
                                run your task smoothly. Partner with us, and together, we can navigate the digital
                                world,
                                ensuring your tasks are not just automated but optimized for maximum impact.
                            </p>
                        </div>
                    </div>
                    <div className={"section enclosed mobile-negative-margin-top-2"}>
                        <div className={"row align-items-center"}>
                            <div className={"half-section"}>
                                <LazyLoad>
                                    <img src={twoRobots} alt={"two-robots"} className={"enclosed-image scale-up"}/>
                                </LazyLoad>
                            </div>
                            <div className={"half-section"}>
                                <LazyLoad>
                                    <div className={"slide-in-right"}>
                                        <div className={"section-heading"}>What is Automation?</div>
                                        <p className={"section-content"}>
                                            Automation is the process of using technology to perform tasks without human
                                            intervention. It involves setting up systems that can execute specific
                                            actions,
                                            often repetitive or time-consuming. Through programming and advanced
                                            algorithms,
                                            automation streamlines workflows to reduce manual labor.
                                            It empowers businesses and individuals to focus on strategic, creative, and
                                            high-value tasks while machines seamlessly handle routine operations.
                                            Companies that
                                            utilize automation can respond to market demands quickly and efficiently,
                                            gaining an
                                            edge over competitors who rely solely on manual processes. It's a strategic
                                            investment that pays dividends in terms of time saved and improved quality.
                                        </p>
                                    </div>
                                </LazyLoad>
                            </div>
                        </div>
                        <div>
                            <div className={"section-heading"}>Core Concepts of Automation</div>
                            <div className={"section-subheading"}>Efficiency through Automation</div>
                            <p className={"section-content"}>
                                Automation streamlines workflows, reducing manual intervention in routine tasks. This
                                leads to faster, error-free operations, enhancing productivity and allocating resources
                                to more strategic endeavors.
                            </p>
                            <div className={"section-subheading"}>Precision in Automated Processes</div>
                            <p className={"section-content"}>
                                Automated systems perform tasks with consistent accuracy and reliability, eliminating
                                human errors such as miscalculations and misinterpretations. This ensures processes are
                                executed precisely as intended, maintaining high quality and integrity.
                            </p>
                            <div className={"section-subheading"}>Scalability in Automation Solutions</div>
                            <p className={"section-content"}>
                                Automation solutions are designed to adapt to varying workloads and demands. Whether the
                                task requires handling a few units or thousands, automated systems can efficiently
                                manage the workload without compromising performance.
                            </p>
                            <div className={"section-subheading"}>Integration for Streamlined Operations</div>
                            <p className={"section-content last-line"}>
                                Automation seamlessly integrates with existing infrastructure and software applications.
                                This allows for a cohesive and synchronized flow of information and processes across
                                different system components, creating an efficient end-to-end operation.
                            </p>
                        </div>
                    </div>
                    <div className={"section enclosed"}>
                        <div className={"row align-items-center"}>
                            <div className={"half-section"}>
                                <LazyLoad>
                                    <div className={"slide-in-left"}>
                                        <div className={"section-heading"}>Why Choose Automation?</div>
                                        <p className={"section-content"}>
                                            Employing automation is a smart move toward maximizing efficiency and
                                            productivity.
                                            By automating routine tasks, you free up valuable time to focus on
                                            high-value,
                                            strategic endeavors. Automation ensures precision and consistency, reducing
                                            the
                                            margin for error. It empowers you to stay competitive in a rapidly evolving
                                            business
                                            landscape, enabling quick responses to market demands. Ultimately,
                                            automation is an
                                            investment in streamlining operations and achieving sustainable growth.
                                        </p>
                                    </div>
                                </LazyLoad>
                            </div>
                            <div className={"half-section"}>
                                <LazyLoad>
                                    <img src={computer} alt={"computer"} className={"enclosed-image scale-up"}/>
                                </LazyLoad>
                            </div>
                        </div>
                        <div>
                            <div className={"section-heading"}>Problems Automation Can Solve</div>
                            <div className={"section-subheading"}>Enhanced Reporting and Analytics
                            </div>
                            <p className={"section-content"}>
                                Automation can generate detailed reports and provide valuable insights from data,
                                enabling you to make informed decisions and track key performance indicators more
                                effectively.
                            </p>
                            <div className={"section-subheading"}>Data Management and Analysis</div>
                            <p className={"section-content"}>
                                Handling large amounts of data and performing complex calculations is a strength of
                                automation. This leads to more accurate and insightful decision-making, eliminating the
                                risk of data discrepancies.
                            </p>
                            <div className={"section-subheading"}>24/7 Availability</div>
                            <p className={"section-content"}>
                                Humans have limitations in terms of working hours, but automation can operate around the
                                clock. This is especially crucial for tasks that require continuous monitoring or
                                immediate responses.
                            </p>
                            <div className={"section-subheading"}>Consistent Process Execution
                            </div>
                            <p className={"section-content"}>
                                Ensuring performance consistency is necessary for any smoothly running operation.
                                Automation guarantees that processes remain unaffected by external factors or individual
                                performance variations. </p>
                            <div className={"section-subheading"}>Cost Reduction
                            </div>
                            <p className={"section-content last-line"}>
                                Automated execution and market analysis reduce labor costs and enable precise resource
                                allocation, leading to more efficient operations and higher productivity.
                            </p>
                        </div>
                    </div>
                    <div className={"section open center"}>
                        <LazyLoad>
                            <div className={"section-heading slide-in-top"}>Daily Email and Report Solutions for All
                            </div>
                        </LazyLoad>
                        <LazyLoad>
                            <p className={"section-content slide-in-top"}>
                                <span className={"bold"}>Proactive Insights</span> - Regular email and report solutions
                                offer invaluable advantages for both businesses and individuals. Firstly, we provide
                                timely
                                insights into key metrics and performance indicators. By receiving regular updates, you
                                can
                                stay well-informed about the status of various operations, allowing for faster responses
                                to
                                emerging trends or issues.
                                <br/>
                                <br/>
                                <span className={"bold"}>Enhanced Communication Efficiency</span> - Daily emails and
                                reports
                                enhance communication efficiency. Instead of sifting through multiple sources of
                                information, critical financial data is consolidated into a single, easily
                                understandable
                                format. This saves time and reduces the chances of overlooking important details. It
                                fosters
                                a streamlined workflow, enabling you to focus on high-priority tasks rather than getting
                                mixed up by gathering and analyzing financial data.
                                <br/>
                                <br/>
                                <span className={"bold"}>Issue Identification and Resolution</span> - Daily reports can
                                flag
                                potential challenges or discrepancies promptly. This allows immediate action, preventing
                                minor problems from escalating into larger, more complex issues. By addressing concerns
                                in
                                real time, you can maintain a smooth operational flow and ensure the reliability and
                                compliance of your work processes.
                            </p>
                        </LazyLoad>
                    </div>
                </div>
                <LazyLoad>
                    <img src={largeComputer} alt={"large-computer"} className={"enclosed-image scale-up"}/>
                </LazyLoad>
                <br/>
                <br/>
                <br/>
                <div className={"section open center margin-bottom-2"}>
                    <div className={"row align-items-center"}>
                        <div className={"half-section"}>
                            <div className={"section-heading mobile-negative-margin-top-2"}>Procedure We Follow</div>
                            <p className={"section-content"}>
                                <span className={"bold"}>Initial Contact</span> - The process begins with clients
                                submitting a contact form, providing their name, email, and a brief description of their
                                requirements. This step sets the foundation for our dedicated team to initiate contact
                                and schedule a phone call.
                                <br/>
                                <br/>
                                <span className={"bold"}>Comprehensive Consultation</span> - During the scheduled call,
                                we'll discuss the details with the client. This allows us to gather essential
                                information about their requirements, specific goals, and any challenges they may face.
                                This phase ensures that we thoroughly understand the client's needs.
                                <br/>
                                <br/>
                                <span className={"bold"}>In-Depth Video Consultation</span> - Following the initial
                                consultation, we scheduled a comprehensive video consultation. It involves working
                                closely with the client to gather pertinent information for the automation project.
                                Additionally, we provide a final pricing estimate, offering the client a clear overview
                                of the investment required.
                                <br/>
                                <br/>
                                <span className={"bold"}>Customized Automation Development</span> - Once all details are
                                gathered, we start the development. Utilizing our expertise in programming and advanced
                                algorithms, we craft a tailored solution that aligns precisely with the client's
                                objectives. We ensure seamless integration with their existing infrastructure and
                                applications for a cohesive operational flow.
                                <br/>
                                <br/>
                                <span className={"bold"}>Ongoing Hosting and Maintenance</span> - Our commitment extends
                                beyond implementation. We take responsibility for the hosting and maintaining the
                                automation, ensuring smooth and efficient operation. This encompasses tasks including
                                server setup, routine maintenance, and API (Application Programming Interface) software
                                updates.
                            </p>
                        </div>
                        <div className={"half-section"}>
                            <img src={robotFace} alt={"computer"} className={"enclosed-image"}/>
                        </div>
                    </div>
                </div>
                <ContactForm/>
            </div>
            <Footer/>
        </div>

    )
}

export default About;